import React, { Component } from "react";
// import { Popup } from "react-leaflet";
import CircleMarker from "./CircleMarker";
import Marker from "./Marker";

// import { GeoJSON } from "react-leaflet";
import L, { FeatureGroup } from "leaflet";
import MarkerClusterGroup from "./MarkerCluster";
// import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { Typography } from "@material-ui/core";

//custom star icon for projects
let achenSvgString =
	"<svg xmlns='http://www.w3.org/2000/svg' width='500' height='500'><path d='M2,111 h300 l-242.7,176.3 92.7,-285.3 92.7,285.3 z' fill='#ff00ff'/></svg>";
let myIconUrl = encodeURI("data:image/svg+xml," + achenSvgString).replace(
	"#",
	"%23"
);

//square icon for other monitoring sites
let svgString =
	"<svg xmlns='http://www.w3.org/2000/svg' width='230' height='230' ><path d='M 15 15 H 95 V 95 H 15 L 15 15' stroke='orange' stroke-width='15' fill='#E59400' fill-opacity='0.5'/></svg>";
let mySquareUrl = encodeURI("data:image/svg+xml," + svgString).replace(
	"#",
	"%23"
);

const myIcon = L.icon({
	iconUrl: myIconUrl,
	iconSize: 40,
});

const mySquareIcon = L.icon({
	iconUrl: mySquareUrl,
	iconSize: 40,
});

class PageLayer extends Component {
	iconCreateFunction = (cluster) => {
		return new L.divIcon({
			html: "<div><span>" + cluster.getChildCount() + "</span></div>",
			className: "marker-cluster marker-cluster-" + this.props.clusterColor,
			iconSize: new L.Point(40, 40),
		});
	};

	mapPosition(item) {
		return [item.geometry.coordinates[1], item.geometry.coordinates[0]];
	}

	pointToLayer(feature, latlng) {
		var _this = this;
		//create the specific type of popups on the screen
		if (_this.props.info.type2 === "project_forms") {
			return Marker(latlng, {
				radius: 8,
				icon: myIcon,
				bubblingMouseEvents: true,
			});
		} else if (_this.props.info.type2 === "waater_level_sites_other") {
			return L.marker(latlng, { radius: 8, icon: mySquareIcon });
		} else return L.circleMarker(latlng, { radius: 8 });
	}

	//bind pop ups to the icons only use if we use the GEOSJSON component that wasnt able to get the URL to take EPSG 4326
	//refer to MAP.js --wmsidentify() for layout of params, issue is with mapserver not liking X/Y or bbox parameters are return is blanks
	identify(feature, layer) {
		console.log(feature);
		const { ref } = this.props;
		if (layer)
			layer.bindPopup(function (l) {
				var layer_names = [
					"wells",
					"subsidencesite",
					"streamsite",
					"projectform",
				];
				var params = {};
				params["layers"] = layer_names;
				params["query_layers"] = layer_names;

				var map = this._map;
				var bounds = this._map.getBounds();
				var size = map.getSize();
				var crs = map.options.crs;
				var nw = crs.project(bounds.getNorthWest());
				var se = crs.project(bounds.getSouthEast());

				params["request"] = "GetFeatureInfo";
				params["INFO_FORMAT"] = "text/html";
				params["url"] = "https://maderadms.houstoneng.net/cgi-bin/mapserv";
				params["map"] = "/var/www/madera/maps/map.map"; //this is the mapserver doc that will call the layers as well as popup html files
				params["version"] = "1.1.1";
				params["pathname"] = "/cgi-bin/mapserv";
				params["service"] = ["WMS"];
				params["srs"] = "EPSG:3857";
				params["request"] = "GetFeatureInfo";
				params["X"] = l._point ? l._point.x : this._map._pixelOrigin.x;
				params["Y"] = l._point ? l._point.y : this._map._pixelOrigin.y;
				params["width"] = size.x;
				params["height"] = size.y;
				params["bbox"] = [nw.x, se.y, se.x, nw.y].join(",");

				var url = new URL(params["url"]);

				Object.keys(params).forEach((key) =>
					url.searchParams.append(key, params[key])
				);
				map.openPopup(
					"<iframe id='wmsiden' src='" +
						url +
						"' style='border:none'></iframe>",
					this._latlng
				);
			});
	}

	render() {
		const {
			legend,
			clusterColor,
			clusterHex,
			features,
			/*	title,
			link,
			agencies,*/
			key,
			star,
			square,
			markerSize,
		} = this.props;
		// var newFeatures = [];
		// features.forEach((f) => (f.geometry ? newFeatures.push(f.geometry) : null));
		return (
			<MarkerClusterGroup
				key={key}
				legend={legend}
				clusterColor={clusterColor}
				disableClusteringAtZoom={0}
				spiderfyOnMaxZoom={false}
				iconCreateFunction={this.iconCreateFunction}>
				{/* <GeoJSON  //not used need to figure out how to pass getfeatureinfo to backend for marker as doesnt show point on screen 
					data={newFeatures}
					key={key}
					style={{ color: clusterHex }}
					pointToLayer={this.pointToLayer.bind(this)}
					onEachFeature={this.identify.bind(this)}
				/> */}

				{features &&
					features.map((item) => {
						if (!item.geometry) return null;
						if (star || square) {
							//projects show a star icon
							return (
								<Marker
									icon={star ? myIcon : mySquareIcon}
									key={item.id}
									color={clusterColor}
									radius={markerSize}
									position={this.mapPosition(item)}
									center={this.mapPosition(item)}
									bubblingMouseEvents={true}></Marker>
							);
						} else
							return (
								<CircleMarker
									key={item.id}
									color={clusterColor}
									radius={markerSize}
									position={this.mapPosition(item)}
									center={this.mapPosition(item)}></CircleMarker>
							);
					})}
			</MarkerClusterGroup>
		);
	}
}

PageLayer = connect(
	(state) => ({
		authState: state.auth,
	}),
	null
)(PageLayer);

export default withRouter(PageLayer);
