import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";
import NumberFormat from "react-number-format";
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";

const historicWaterReporting = createSelector((session, options) => {
	let gsp = session.GSP.all().toRefArray();
	let wy = session.WaterYear.all().orderBy("water_year").toRefArray();
	let gws =
		gsp && wy
			? session.Evapotranspiration.filter((r) => r.submitted === true)
					.orderBy("water_year_id", "desc")
					.orderBy("month", "desc")
					.toRefArray()
					.map((water) => {
						return {
							gwre_id: water.id,
							...water,
							...wy.find((w) => w.id === water.water_year_id),
							...gsp.find((g) => g.id === water.gsp),
						};
					})
			: [];
	if (gws.length) {
		console.log(gws);
	}
	return gws;
});
const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	tableCellIcon: {
		minWidth: 34,
		width: 34,
		marginRight: 0,
	},
	expansionBorder: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
	},
	lastBorder: {
		[theme.breakpoints.up("xl")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	lastBorder2: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	expandedMargin: {
		marginTop: "12px",
		minHeight: "48px !important",
		maxHeight: 48,
	},
	borderContainer: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
		borderRight: "2px solid " + theme.palette.primary.light,
		borderLeft: "2px solid " + theme.palette.primary.light,
	},
	borderContainer2: {
		border: "1px solid rgb(221, 221, 221)",
	},
	buttonSelected: {
		border: "3px solid #13aff1",
		minWidth: "44px",
	},
	buttonNotSelected: {
		border: "3px solid #666",
		minWidth: "44px",
	},
	iconSelected: {
		color: "#13aff1",
	},
	iconNotSelected: {
		color: "#666",
	},
	divideButton: {
		marginBottom: 16,
		width: "calc(50% - 16px)",
		marginTop: 16,
		minWidth: 44,
	},
	divideContainer: {
		backgroundColor: "#ddd",
		textAlign: "center",
		margin: "0 -4px",
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		minWidth: 500,
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	marginLeft: {
		marginLeft: -16,
	},
	chartTitle: {
		backgroundColor: "rgb(221, 221, 221)",
		textAlign: "center",
		padding: "8px 4px !important",
	},
});
class Evapotranspiration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: "desc",
			orderBy: "water_year",
			page: 0,
			rowsPerPage: 10,
			histdata: [],
		};
	}
	componentDidMount() {}
	columnData() {
		const cols = [
			{
				id: "gsp_name_label",
				numeric: false,
				label: "GSP Organization Name",
				allowSort: true,
			},
			{
				id: "gsa_name_label",
				numeric: false,
				label: "GSA Organization Name",
				allowSort: true,
			},
			{ id: "water_year", date: true, label: "Year", allowSort: true },
			{ id: "month", numeric: true, label: "Month", allowSort: true },
			{ id: "details", numeric: true, label: "Details", allowSort: false },
			{
				id: "ur_total",
				numeric: true,
				label: "Urban",
				allowSort: true,
			},

			{
				id: "agg_total",
				numeric: true,
				label: "Agricultural",
				allowSort: true,
			},
			{
				id: "man_total",
				numeric: true,
				label: "Managed Recharge",
				allowSort: true,
			},
			{
				id: "nat_total",
				numeric: true,
				label: "Native Vegetation",
				allowSort: true,
			},
			{
				id: "sur_total",
				numeric: true,
				label: "Surface Water System",
				allowSort: true,
			},

			{
				id: "other_total",
				numeric: true,
				label: "Other",
				allowSort: true,
			},
		];
		return cols;
	}
	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";
		const colType = this.props.match.params["id"];
		const columnData = this.columnData(colType);
		if (this.state.orderBy === property && this.state.order === "desc") {
		  order = "asc";
		}
		this.state.histdata.sort(function (a, b) {
		  var col = columnData.find((cD) => cD.id === orderBy);
	
		  if (col.numeric) {
			if (order === "desc")
			  return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
			else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
		  } 
		  else if(col.date){
			let aInput = "";
			let bInput = ""
			if (order === "desc"){
			  if (a[orderBy] === "") return 1;
			  if (b[orderBy] === "") return -1;
			  aInput = Date.parse(a[orderBy]);
			  bInput = Date.parse(b[orderBy]);
			  return bInput < aInput? -1 : 1;
			}else{
			  if (a[orderBy] === "") return -1;
			  if (b[orderBy] === "") return 1;
			  aInput = Date.parse(a[orderBy]);
			  bInput = Date.parse(b[orderBy]);
			  return aInput < bInput ? -1 : 1;
			}
		  }
		  else{
			if (order === "desc")
			  return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
			else
			  return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
		  }
		});
		this.setState({ order, orderBy });
	};
	handleChangePage = (event, page) => {
		this.setState({ page });
	};
	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};
	render() {
		const { classes, waterhist } = this.props;
		const { page, rowsPerPage, order, orderBy, histdata } = this.state;
		if (waterhist && waterhist.length) {
			if (!histdata.length) {
				this.setState({ histdata: waterhist });
			}
		}
		let level1 = "Evapotranspiration Data";
		let level1link = "/evapotranspiration";
		return (
			<AppContainer authenticated>
				<Grid container spacing={24}>
					<Grid item xs={12} className={classes.breadCrumb}>
						<BreadcrumbNav level1={level1} level1link={level1link} />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">
							Submitted Evapotranspiration Data
						</Typography>
					</Grid>
					{histdata ? (
						<Grid item xs={12}>
							<Paper className={classes.root}>
								<Table className={classes.table}>
									<EnhancedTableHead
										columnData={this.columnData()}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
									/>
									<TableBody>
										{histdata
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((item, index) => (
												<TableRow key={index}>
													<TableCell>{item.gsp_name_label}</TableCell>{" "}
													<TableCell>{item.gsa_name_label}</TableCell>
													<TableCell>{item.water_year} </TableCell>
                                					<TableCell>{item.month} </TableCell>
													<TableCell>
														<Tooltip title={"View Data"}>
															<Button
																className={classes.tableCellIcon}
																onClick={() =>
																	this.props.history.push({
																		pathname:
																			"/reporting/evap/" +
																			item.gsp_name_id +
																			"/" +
																			item.gwre_id,
																	})
																}>
																<Icon
																	path={mdiEye}
																	size={1}
																	color="primary"></Icon>{" "}
															</Button>
														</Tooltip>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.dom_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.agg_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.man_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.nat_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sur_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.other_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
												</TableRow>
											))}
										{histdata.length < 1 && (
											<TableRow>
												<TableCell colSpan={7} className={classes.centerAlign}>
													No Historical Data Found
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									{histdata.length > rowsPerPage && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={7}
													count={histdata.length}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={this.handleChangePage}
													onChangeRowsPerPage={this.handleChangeRowsPerPage}
													ActionsComponent={TablePaginationActionsWrapped}
												/>
											</TableRow>
										</TableFooter>
									)}
								</Table>
							</Paper>
						</Grid>
					) : (
						<></>
					)}
				</Grid>
			</AppContainer>
		);
	}
}
Evapotranspiration = connect(
	(state, ownProps) => ({
		waterhist: historicWaterReporting(state, ownProps),
		authState: state.auth,
	}),
	{}
)(Evapotranspiration);
export default withStyles(styles)(Evapotranspiration);
