import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
// import { createSelector } from "../common/orm";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
	ReferenceLine,
} from "recharts";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import WellSiteDetails from "../../api/wellsiteDetailsHOC";
import AppContainer from "../common/AppContainer";

const styles = (theme) => ({
	centerAlign: {
		textAlign: "center",
	},
	marginLeft: {
		marginLeft: -16,
	},

	marginTop: {
		marginTop: 8,
		marginRight: 8,
	},
});

class Hydrograph extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonState: "chart",
			order: "desc",
			orderBy: "measurement_date",
			page: 0,
			rowsPerPage: 10,
			y_axis: "surf_elev",
			y_axis_label: "Surface Elevation",
			start_date: null,
			end_date: null,
			min_value: null,
			max_value: null,
			mapState: false,
			measurements: [],
		};
		this.formKey = window.performance.now();
	}

	componentDidUpdate() {
		const { start_date, measurements } = this.state;
		if (measurements.length > 0 && !start_date) {
			this.setState({
				start_date: this.getMinDate(measurements).getFullYear(),
				end_date: new Date().getFullYear(),
			});
			this.formKey = window.performance.now();
		}
	}

	getMinDate(arr) {
		return new Date(
			arr.reduce(
				(min, p) =>
					new Date(p.measurement_date + " 12:00:00") < min
						? new Date(p.measurement_date + " 12:00:00")
						: min,
				new Date(arr[0].measurement_date + " 12:00:00")
			)
		);
	}

	getMaxDate(arr) {
		return new Date(
			arr.reduce(
				(max, p) =>
					new Date(p.measurement_date + " 12:00:00") > max
						? new Date(p.measurement_date + " 12:00:00")
						: max,
				new Date(arr[0].measurement_date + " 12:00:00")
			)
		);
	}

	getMinMaxVal(arr, val, site) {
		let value = val === "surf_elev" ? "surf_elev" : "measurement_depth";
		let min = 1000000000,
			max = 0;

		for (let i = 1, len = arr.length; i < len; i++) {
			let v = arr[i][value];
			min = v < min ? v : min;
			max = v > max ? v : max;
		}

		if (value === "surf_elev") {
			if (site.gsp_threshold > max) {
				max = site.gsp_threshold;
			}
			if (
				site.measurable_objective &&
				parseFloat(site.measurable_objective) > max
			) {
				max = parseFloat(site.measurable_objective);
			}
			if (site.ground_surface_elev > max) {
				max = site.ground_surface_elev;
			}

			if (site.gsp_threshold < min) {
				min = site.gsp_threshold;
			}
			if (
				site.measurable_objective &&
				parseFloat(site.measurable_objective) < min
			) {
				min = parseFloat(site.measurable_objective);
			}
			if (site.ground_surface_elev < min) {
				min = site.ground_surface_elev;
			}
		}

		if (min && min !== 0) {
			min = this.round(min - 5, 0);
		}

		max = this.round(max + 5, 0);

		return { min, max };
	}

	round(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	handleAPIChange(data) {
		this.setState({ site: data.site, measurements: data.site.measurements });
	}

	render() {
		const { classes, authState } = this.props;
		const {
			min_value,
			max_value,
			y_axis,
			y_axis_label,
			start_date,
			end_date,
			site,
			measurements,
		} = this.state;
		if (!authState || !authState.user) return "";
		let siteId = parseInt(this.props.match.params["id"]);

		// Custom formatting of values for chart
		if (measurements && measurements.length > 0) {
			var filtered_measurements = measurements.slice();

			filtered_measurements.forEach(function (m) {
				if (!m.chart_date && m.measurement_date) {
					var d;
					if (m.measurement_time && m.meaurement_time !== "")
						d = new Date(m.measurement_date + " " + m.measurement_time);
					else d = new Date(m.measurement_date + " 12:00:00");
					m.chart_date = d.getTime();
				}
				if (m.measurement_depth) {
					m.measurement_depth = parseFloat(m.measurement_depth);
				}
				if (m.surf_elev) {
					m.surf_elev = parseFloat(m.surf_elev);
				}
			});

			filtered_measurements.sort((m, n) => m.chart_date - n.chart_date);
			var minDate = this.getMinDate(filtered_measurements);
			var maxDate = this.getMaxDate(filtered_measurements);
			var yearoptions = [];
			var y = minDate.getFullYear();
			var z = new Date().getFullYear();
			while (y <= z) {
				yearoptions.push({ label: y, value: y });
				y++;
			}
			if (start_date) {
				filtered_measurements = filtered_measurements.filter(
					(m) =>
						new Date(m.measurement_date).getFullYear() >= parseInt(start_date)
				);
			}
			if (end_date) {
				filtered_measurements = filtered_measurements.filter(
					(m) =>
						new Date(m.measurement_date).getFullYear() <= parseInt(end_date)
				);
			}
		}

		if (site === undefined) {
			if (authState && authState.user)
				return (
					<WellSiteDetails
						apitarget={"wellsitemeasurements"}
						siteid={siteId}
						handler={this.handleAPIChange.bind(this)}
					/>
				);
		} else {
			return (
				// <AppContainer authenticated>
				<Grid container spacing={24}>
					<>
						{measurements.length === 0 && (
							<Grid item xs={12}>
								<Typography variant="h5" className={classes.centerAlign}>
									No Measurements Found
								</Typography>
							</Grid>
						)}
						{measurements.length > 0 && (
							<>
								<Grid item xs={8}>
									<ResponsiveContainer
										width={456} //"100%"
										height={650}
										ref="chartContainer">
										<LineChart
											data={
												y_axis === "surf_elev"
													? filtered_measurements.filter(
															(s) => s.surf_elev != null
													  )
													: filtered_measurements.filter(
															(s) => s.measurement_depth != null
													  )
											}>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis
												dataKey="chart_date"
												name="Measurement Date"
												domain={[
													this.getMinDate(filtered_measurements),
													this.getMaxDate(filtered_measurements),
												]}
												type="number"
												tickFormatter={(unixTime) =>
													moment(unixTime).format("MM/DD/YYYY")
												}
											/>

											{y_axis === "surf_elev" && (
												<YAxis
													// domain has to be in functions otherwise it doesnt update?
													domain={[
														(dataMin) =>
															min_value
																? parseFloat(min_value)
																: this.getMinMaxVal(
																		filtered_measurements,
																		y_axis,
																		site
																  ).min,
														(dataMax) =>
															max_value
																? parseFloat(max_value)
																: this.getMinMaxVal(
																		filtered_measurements,
																		y_axis,
																		site
																  ).max,
													]} // 'auto' doesnt work instead of math.ceil
													dataKey={y_axis}
													type="number">
													<Label value="feet" position="middle" angle={-90} />
												</YAxis>
											)}

											{y_axis === "measurement_depth" && (
												<YAxis
													//flip the min/max
													domain={[
														(dataMax) =>
															max_value
																? parseFloat(max_value)
																: this.getMinMaxVal(
																		filtered_measurements,
																		y_axis,
																		site
																  ).max,
														(dataMin) =>
															min_value ? parseFloat(min_value) : 0,
													]} // 'auto' doesnt work instead of math.ceil
													dataKey={y_axis}
													type="number">
													<Label value="feet" position="middle" angle={-90} />
												</YAxis>
											)}

											<Tooltip
												labelFormatter={(value) =>
													new Date(value).toLocaleDateString()
												}
											/>
											<Legend />
											<Line
												connectNulls
												isAnimationActive={false} // Dots are broken on re-render if true https://github.com/recharts/recharts/issues/804
												name={y_axis_label}
												type="linear"
												dataKey={y_axis}
												stroke="#0080be"
											/>
											{y_axis === "surf_elev" && site.gsp_threshold && (
												<ReferenceLine
													y={site.gsp_threshold}
													label={
														"Minimum Threshold " +
														this.round(site.gsp_threshold, 1)
													}
													stroke="red"
												/>
											)}

											{y_axis === "surf_elev" &&
												parseFloat(site.measurable_objective) && (
													<ReferenceLine
														y={parseFloat(site.measurable_objective)}
														label={
															"Measureable Objective " +
															this.round(
																parseFloat(site.measurable_objective),
																1
															)
														}
														stroke="green"
													/>
												)}

											{y_axis === "surf_elev" && site.ground_surface_elev && (
												<ReferenceLine
													y={site.ground_surface_elev}
													label={
														"Ground Surface Elevation " +
														this.round(site.ground_surface_elev, 1)
													}
													stroke="brown"
												/>
											)}
										</LineChart>
									</ResponsiveContainer>
								</Grid>
							</>
						)}
					</>
				</Grid>
				// </AppContainer>
			);
		}
	}
}
Hydrograph = connect(
	(state, ownProps) => ({ authState: state.auth, navState: state.nav }),
	{}
)(Hydrograph);

export default withStyles(styles)(Hydrograph);
