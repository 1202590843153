import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BackButton from "../common/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Submit from "../common/Submit";
import TextField from "../common/TextField";
import AppContainer from "../common/AppContainer";

import { connect } from "react-redux";
import * as authActions from "./actions";
import * as navActions from "../common/actions"; // Make my own.

import * as app_settings from "../../app_settings";

// This is for the login form.
const styles = (theme) => ({
  loginHeaders: {
    fontSize: 24,
    fontWeight: 100,
    marginBottom: 16,
    color: theme.palette.primary,
  },
  loginError: {
    color: "red",
    "text-align": "center",
    "font-size": 16,
    marginBottom: 24,
  },
  marginBottomButton: {
    marginBottom: 24,
  },
  greyButton: {
    backgroundColor: "#808080",
    color: "#fff",
  },
  linkColor: {
    color: "#808080",
    float: "right",
    marginTop: 8,
    fontSize: 16,
    fontWeight: 300,
  },
});

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basin: "Madera Subbasin",
      dmsemail: "dmssupport@maderagroup.org",
    };
  }

  logOut = () => {
    this.props.authLogout();
  };

  continueDashboard = () => {
    this.props.navSelectPage("dashboard");
    this.props.history.push("/dashboard");
  };
  errorValidator = (values) => {
    const validateEmail = (email) => {
      return !email ? "Email is required." : null;
    };
    const validatePassword = (password) => {
      return !password ? "Password is required." : null;
    };
    return {
      email: validateEmail(values.email),
      password: validatePassword(values.password),
    };
  };

  loginSubmit = (values, e, formApi) => {
    this.props.authLogin(values).then(() => {
      if (this.props.authState.error) {
        formApi.setValue("password", "");
      } else if (this.props.authState.user) {
        this.continueDashboard();
      }
    });
  };

  render() {
    const { classes, authState } = this.props;
    if (!authState) return "...";
    if (authState !== undefined) {
      if (authState.user) {
        return (
          <AppContainer>
            <Grid container spacing={24} justify="center">
              <Grid item xs={12} md={9} lg={6} xl={3}>
                <Typography variant="h5" className={classes.loginHeaders}>
                  Welcome, {authState.user.first_name}
                </Typography>
                <Button
                  onClick={this.continueDashboard}
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.marginBottomButton}
                >
                  Continue to Dashboard
                </Button>
                <Button
                  onClick={this.logOut}
                  variant="contained"
                  fullWidth
                  className={classes.greyButton}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </AppContainer>
        );
      } else {
        return (
          <AppContainer>
            <Grid container spacing={24}>
              <Grid item xs={12}></Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{ color: "black" }}
                  className={classes.loginHeaders}
                >
                  Welcome to the {app_settings.basin} DMS
                </Typography>
                <div>
                  Welcome to the {app_settings.basin} Sustainable Groundwater
                  Management Act Data Management System (DMS), a shared resource
                  for all 24 Groundwater Sustainability Agencies (GSAs) in the
                  Madera Subbasin. This DMS is dedicated to centralizing and
                  aggregating data used for SGMA coordination and annual
                  reporting.
                </div>
              </Grid>
              <Grid item xs={3}></Grid>

              <Grid item xs={3}></Grid>

              <Grid item xs={3} style={{ borderRight: "1px solid #549ff7" }}>
                <Form
                  dontValidateOnMount="true"
                  validateOnSubmit="true"
                  validateError={this.errorValidator}
                  onSubmit={this.loginSubmit}
                >
                  {(formApi) => (
                    <form onSubmit={formApi.submitForm}>
                      <Typography className={classes.loginHeaders}>
                        Login
                      </Typography>
                      <TextField
                        field="email"
                        label="Email"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        field="password"
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                      />
                      <div className={classes.loginError}>
                        {authState.error}
                      </div>
                      <Submit
                        label="Login"
                        gridSize={24}
                        loading={authState.pending}
                        // altLabel2="Forget your password?"
                        // altAction2="/password/reset"
                      />
                    </form>
                  )}
                </Form>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" className={classes.loginHeaders}>
                  New Users
                </Typography>
                <Typography>
                  <br />
                </Typography>
                <br />
                <BackButton
                  altLabel="Register"
                  gridSize={24}
                  altAction="/register"
                />
                <br />
                Need Help?
                <br />
                Contact us at
                <br />
                <a href="mailto:{app_settings.contact_email}">
                  {app_settings.contact_email}
                </a>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </AppContainer>
        );
      }
    } else {
      return <div>Log In Not Logged In Page</div>;
    }
  }
}

LoginPage = connect((state) => ({ authState: state.auth }), {
  ...authActions,
  ...navActions,
})(LoginPage);

export default withStyles(styles)(withRouter(LoginPage));
