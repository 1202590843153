import { Marker as LeafletMarker } from "leaflet";
import { withLeaflet, Path } from "react-leaflet";

class Marker extends Path {
	//created a new leaflet marker with position and (options = color, center & image)
	createLeafletElement({ position, leaflet, ...options }) {
		return new LeafletMarker(position, options);
	}
}
export default withLeaflet(Marker);
