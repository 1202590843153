import React, { Component } from "react";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import CustomTableCell from "./TableCell";

const styles = {
	centerAlign: {
		textAlign: "center",
	},
};

class EnhancedTableHead extends Component {
	createSortHandler = (property) => (event) => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, classes, orderBy, columnData, actions } = this.props;

		return (
			<TableHead>
				<TableRow>
					{actions && (
						<CustomTableCell
							key={"actions"}
							sortDirection={false}
							style={{ width: "auto" }}>
							<Typography>{"Actions"}</Typography>
						</CustomTableCell>
					)}
					{columnData.map((column) => {
						return (
							<CustomTableCell
								key={column.id}
								sortDirection={orderBy === column.id ? order : false}
								style={{ width: column.width ? column.width : "auto" }}
								className={column.center ? classes.centerAlign : ""}>
								{column.allowSort && (
									<Tooltip
										title="Sort"
										placement="bottom-start"
										enterDelay={300}>
										<TableSortLabel
											active={orderBy === column.id}
											direction={order}
											style={{
												width: column.width ? column.width : "auto",
												whiteSpace: "normal",
											}}
											onClick={this.createSortHandler(column.id)}>
											<Typography>{column.label}</Typography>
										</TableSortLabel>
									</Tooltip>
								)}
								{!column.allowSort && <Typography>{column.label}</Typography>}
							</CustomTableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

export default withStyles(styles)(EnhancedTableHead);
