import React, { Component } from "react";
import { connect } from "react-redux";
// import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import NotReady from "../common/NotReady";
import ReportingStatus from "../../api/reportingStatusHOC";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

import Icon from "@mdi/react";
import { mdiEye, mdiDatabaseImport } from "@mdi/js";
import requestConfig, { trimColumns } from "../../api/fetchConfig";

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	tableCellIcon: {
		minWidth: 34,
		width: 34,
		marginRight: 0,
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		width: "100%",
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	lgHeader: {
		fontSize: "25px",
	},
});

const columnData = [
	{
		id: "name",
		numeric: false,
		label: "GSP Name",
		allowSort: true,
	},
	{
		id: "upload",
		numeric: false,
		label: "Import Monitoring Data",
		allowSort: false,
	},
	{
		id: "gw_submitted",
		numeric: false,
		label: "Water Level Monitoring",
		allowSort: true,
	},
	{
		id: "wq_submitted",
		numeric: false,
		label: "Water Quality Monitoring",
		allowSort: true,
	},
	{
		id: "sub_submitted",
		numeric: false,
		label: "Subsidence Monitoring",
		allowSort: true,
	},
	{
		id: "stream_submitted",
		numeric: false,
		label: "Stream Monitoring Sites",
		allowSort: true,
	},
	{
		id: "gwe_submitted",
		numeric: false,
		label: "GW Extraction Measurements",
		allowSort: true,
	},
];

class ImportMonitoringGSPs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: "desc",
			orderBy: "water_year",
			page: 0,
			rowsPerPage: 10,
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}

	handleRequestSort = (event, property) => {
		const { existingData } = this.state;
		const orderBy = property;
		let order = "desc";

		let data = existingData;

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		data.sort(function (a, b) {
			var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
			if (numeric) {
				if (order === "desc")
					return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
				else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
			} else {
				if (order === "desc")
					return (b[orderBy] || "").toUpperCase() <
						(a[orderBy] || "").toUpperCase()
						? -1
						: 1;
				else
					return (a[orderBy] || "").toUpperCase() <
						(b[orderBy] || "").toUpperCase()
						? -1
						: 1;
			}
		});

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	handleAPIChange(data) {
		if (data.user_role === "GSP Representative" || data.user_role === 'Administrator') {
			this.setState({ authorized: true });
		}

		this.setState({ existingData: data.gsp, water_year: data.water_year });
	}

	render() {
		const { classes, authState } = this.props;
		const {
			page,
			rowsPerPage,
			order,
			orderBy,
			authorized,
			existingData,
			appConfig,
			isFetching,
		} = this.state;

		let gsp_items = existingData ? existingData : [];

		let token = authState && authState.user ? authState.user.auth_token : "";

		//get the config data
		if (token !== "" && appConfig === undefined && !isFetching) {
			this.setState({ ...this.state, isFetching: true });
			requestConfig(token).then((data) => {
				this.setState({ appConfig: data, isFetching: false });
			});
		}

		if (existingData === undefined || appConfig === undefined) {
			return (
				<AppContainer authenticated>
					<ReportingStatus
						apitarget={"importstatus"}
						handler={this.handleAPIChange.bind(this)}
					/>
					<NotReady message="Loading..." />
				</AppContainer>
			);
		} else {
			return (
				<AppContainer authenticated>
					<Grid container spacing={24}>
						<Grid item xs={12} className={classes.breadCrumb}>
							<BreadcrumbNav level1="Associated GSP's" level1link="/import" />
						</Grid>
						<Grid item xs={12}>
							<span className={classes.lgHeader}>
								Import Monitoring Data for Associated GSP's
							</span>
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.root}>
								<Table className={classes.table}>
									<EnhancedTableHead
										columnData={trimColumns(columnData, appConfig)}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
									/>
									<TableBody>
										{gsp_items
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((item) => (
												<TableRow key={item.id}>
													<TableCell>{item.name}</TableCell>
													{appConfig.IMD.enabled === true ? (
														<TableCell>
															{item.authorized_reporter &&
															item.monitoringimport &&
															authorized || this.props.authState.user.role === 'Administrator' ? (
																<Tooltip title="Import Monitoring Data">
																	<Button
																		className={classes.deleteWidth}
																		component={Link}
																		to={"/import/" + item.id}>
																		<Icon
																			path={mdiDatabaseImport}
																			size={1}
																			color="primary"></Icon>{" "}
																	</Button>
																</Tooltip>
															) : (
																<></>
															)}
														</TableCell>
													) : (
														<></>
													)}
													{appConfig.WLM.enabled === true ? (
														<TableCell>
															{item.gw_required === true ? (
																<>
																	{item.gw_submitted !== "None" ? (
																		<>
																			<Tooltip title={"View Data"}>
																				<Button
																					className={classes.tableCellIcon}
																					onClick={() =>
																						this.props.history.push({
																							pathname:
																								"/importHistory/wellsitemeasurements/" +
																								item.id,
																						})
																					}>
																					<Icon
																						path={mdiEye}
																						size={1}
																						color="primary"></Icon>{" "}
																				</Button>
																			</Tooltip>
																		</>
																	) : (
																		<></>
																	)}
																	{item.gw_submitted}
																</>
															) : (
																<>{item.gw_submitted}</>
															)}
														</TableCell>
													) : (
														<></>
													)}
													{appConfig.WQM.enabled === true ? (
														<TableCell>
															{item.wq_required === true ? (
																<>
																	{item.wq_submitted !== "None" ? (
																		<>
																			<Tooltip title={"View Data"}>
																				<Button
																					className={classes.tableCellIcon}
																					onClick={() =>
																						this.props.history.push({
																							pathname:
																								"/importHistory/waterqualitymeasurements/" +
																								item.id,
																						})
																					}>
																					<Icon
																						path={mdiEye}
																						size={1}
																						color="primary"></Icon>{" "}
																				</Button>
																			</Tooltip>
																		</>
																	) : (
																		<></>
																	)}
																	{item.wq_submitted}
																</>
															) : (
																<>{item.wq_submitted}</>
															)}
														</TableCell>
													) : (
														<></>
													)}
													{appConfig.SM.enabled === true ? (
														<TableCell>
															{item.sub_required === true ? (
																<>
																	{item.sub_submitted !== "None" ? (
																		<>
																			<Tooltip title={"View Data"}>
																				<Button
																					className={classes.tableCellIcon}
																					onClick={() =>
																						this.props.history.push({
																							pathname:
																								"/importHistory/subsidencemeasurements/" +
																								item.id,
																						})
																					}>
																					<Icon
																						path={mdiEye}
																						size={1}
																						color="primary"></Icon>{" "}
																				</Button>
																			</Tooltip>
																		</>
																	) : (
																		<></>
																	)}
																	{item.sub_submitted}
																</>
															) : (
																<>{item.sub_submitted}</>
															)}
														</TableCell>
													) : (
														<></>
													)}
													{appConfig.ADMIN.STMS.enabled === true ? (
														<TableCell>
															{item.stream_required === true ? (
																<>
																	{item.stream_submitted !== "None" ? (
																		<>
																			<Tooltip title={"View Data"}>
																				<Button
																					className={classes.tableCellIcon}
																					onClick={() =>
																						this.props.history.push({
																							pathname:
																								"/importHistory/streammonitoring/" +
																								item.id,
																						})
																					}>
																					<Icon
																						path={mdiEye}
																						size={1}
																						color="primary"></Icon>{" "}
																				</Button>
																			</Tooltip>
																		</>
																	) : (
																		<></>
																	)}
																	{item.stream_submitted}
																</>
															) : (
																<>{item.stream_submitted}</>
															)}
														</TableCell>
													) : (
														<></>
													)}
													{appConfig.GE.enabled === true ? (
														<TableCell>
															{item.gwe_required === true ? (
																<>
																	{item.gwe_submitted !== "None" ? (
																		<>
																			<Tooltip title={"View Data"}>
																				<Button
																					className={classes.tableCellIcon}
																					onClick={() =>
																						this.props.history.push({
																							pathname:
																								"/importHistory/groundwaterextraction/" +
																								item.id,
																						})
																					}>
																					<Icon
																						path={mdiEye}
																						size={1}
																						color="primary"></Icon>{" "}
																				</Button>
																			</Tooltip>
																		</>
																	) : (
																		<></>
																	)}
																	{item.gwe_submitted}
																</>
															) : (
																<>{item.gwe_submitted}</>
															)}
														</TableCell>
													) : (
														<></>
													)}
												</TableRow>
											))}
										{gsp_items.length < 1 && (
											<TableRow>
												<TableCell colSpan={4} className={classes.centerAlign}>
													No GSPs Found
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									{gsp_items.length > 25 && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={4}
													count={gsp_items.length}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={this.handleChangePage}
													onChangeRowsPerPage={this.handleChangeRowsPerPage}
													ActionsComponent={TablePaginationActionsWrapped}
												/>
											</TableRow>
										</TableFooter>
									)}
								</Table>
							</Paper>
						</Grid>
					</Grid>
				</AppContainer>
			);
		}
	}
}
ImportMonitoringGSPs = connect(
	(state, ownProps) => ({
		authState: state.auth,
	}),
	{}
)(ImportMonitoringGSPs);

export default withStyles(styles)(ImportMonitoringGSPs);
