import React from "react";
import { connect } from "react-redux";
import { createSelector } from "../components/common/orm";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Icon from "@mdi/react";

const waterYear = createSelector((session, id) => {
	let wy = session.WaterYear.filter(
		(y) => y.is_active === true
	).toRefArray()[0];
	return {
		...wy,
	};
});

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://maderadms.houstoneng.net";

const styles = {
	supportIcon: {
		width: "3em",
		height: "3em",
		color: "#fff",
		marginLeft: 24,
	},
	titleContainer: {
		backgroundColor: "#b20000",
		textAlign: "center",
	},
	questionTitle: {
		marginTop: ".6em",
		color: "#b20000",
		fontWeight: 500,
	},
	greyButton: {
		backgroundColor: "#808080",
		color: "#fff",
	},
	centerButtons: {
		justifyContent: "center",
		marginTop: 24,
	},
	primaryButton: {
		backgroundColor: "#b20000",
		color: "#fff",
	},
	nooverflow: {
		overflow: "hidden",
	},
	closeIcon: {
		float: "right",
		color: "#fff",
		marginTop: -16,
		marginRight: -16,
	},
	tableCellIcon: {
		minWidth: 34,
		width: 34,
		marginRight: 0,
	},
	root: {
		display: "flex",
		"& > * + *": {
			marginLeft: 2,
		},
	},
	loading: {
		width: "20px !important",
		height: "20px !important",
	},
};

class WarningDeleteDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
			tooltipOpen: false,
		};
	}

	render() {
		const {
			title,
			text,
			classes,
			cancelAction,
			confirmText,
			cancelText,
			noActions,
			onClose,
			...other
		} = this.props;

		return (
			<Dialog
				{...other}
				onClose={onClose}
				classes={{ paper: classes.nooverflow }}>
				<DialogTitle className={classes.titleContainer}>
					<ErrorOutline className={classes.supportIcon} />
					<IconButton
						onClick={noActions ? onClose : cancelAction}
						className={classes.closeIcon}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Typography
						variant="h6"
						className={classes.questionTitle}
						gutterBottom>
						{title}
					</Typography>
					<DialogContentText>{text}</DialogContentText>
					{!noActions && (
						<DialogActions className={classes.centerButtons}>
							<Button
								className={classes.buttons}
								onClick={() => this.fetchData()}
								disabled={this.state.isFetching}
								variant="contained"
								color="primary">
								{this.state.isFetching ? (
									<div className={classes.root}>
										<CircularProgress className={classes.loading} />
									</div>
								) : (
									<></>
								)}
								{confirmText ? confirmText : "Delete Entry"}
							</Button>
							<Button
								onClick={cancelAction}
								variant="contained"
								className={classes.greyButton}
								autoFocus>
								{cancelText ? cancelText : "Do Not Delete"}
							</Button>
						</DialogActions>
					)}
				</DialogContent>
			</Dialog>
		);
	}

	fetchData = () => {
		//options for apitarget: [reportingstatus,importstatus,combinedstatus]

		let URL = isLocalhost ? LOCAL_URL : PROD_URL;

		const { authstate, water_year, datatarget, /* dataAction,*/ gsp } =
			this.props;

		let token = authstate && authstate.user ? authstate.user.auth_token : "";

		this.setState({ ...this.state, isFetching: true });

		let payload = {
			datasetname: datatarget,
			gsp_id: gsp,
			water_year: water_year.water_year,
		};

		fetch(URL + "/bulkdelete", {
			method: "POST",
			body: JSON.stringify(payload),
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				this.setState({ ...this.state, isFetching: false });
				this.props.handlerAction(result);
			})
			.catch((e) => {
				console.log(e);
				this.setState({ ...this.state, isFetching: false });
			});
	};
}
WarningDeleteDialog = connect(
	(state, ownProps) => ({
		water_year: waterYear(state, ownProps),
		authstate: state.auth,
	}),
	{}
)(WarningDeleteDialog);

export default withStyles(styles)(WarningDeleteDialog);
