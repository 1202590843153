import React from "react";
import { Map, Marker, TileLayer, WMSTileLayer } from "react-leaflet";

import * as app_settings from "../../../app_settings";

class WaterLevelDetailMap extends React.Component {
    constructor() {
        super();
        this.state = app_settings.map_initial_state;
    }

    render() {
        const map_position = [this.state.lat, this.state.lng];
        const marker_position = this.props.geometry
            ? [this.props.geometry[1], this.props.geometry[0]]
            : [this.state.lat, this.state.lng];

        const { zoom } = this.state;

        return (
            <Map
                className="waterleveldetailmap-container"
                height={this.props.height}
                width={this.props.width}
                center={map_position}
                zoom={zoom}>
                <Marker position={marker_position}></Marker>
                {app_settings.map_layers
                    .filter(l => l.add === true && l.type === "baselayer")
                    .map(l => (
                        <TileLayer attribution={l.attribution} url={l.url} />
                    ))}

                {app_settings.map_layers
                    .filter(l => l.id === "gsa_boundaries")
                    .map(l => (
                        <WMSTileLayer
                            layers={l.layers}
                            transparent={l.transparent}
                            opacity={l.opacity}
                            format={l.format}
                            map={l.map}
                            legend={l.legend}
                            url={l.url}
                        />
                    ))}
            </Map>
        );
    }
}

export default WaterLevelDetailMap;
