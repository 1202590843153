import React from "react";
import { GSA, GSP } from "./models";
import {
	ProjectForm,
	ProjectRepeatableTable,
	AnnualRepeatableTable,
} from "../wells/models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
import ContactMail from "@material-ui/icons/ContactMail";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RoomIcon from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@mdi/react";
import NumberFormat from "react-number-format";
import { mdiUndo, mdiPlus } from "@mdi/js";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import Select from "../common/Select";
import TextField from "../common/TextField";
import { createSelector } from "../common/orm";
import HelpLabel from "../common/HelpLabel";
import EditMap from "../common/map/EditMap";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
// import TablePaginationActionsWrapped from "../common/Paginator";

const projForm = ProjectForm.selectByUrlId();

//these bring back the choices on the models for the selects with the .then in the render
const getProjOptions = ProjectForm.getOptions();
const getProjRepeatOptions = ProjectRepeatableTable.getOptions();
const getWaterOptions = AnnualRepeatableTable.getOptions();

const getGSAs = GSA.selectAll("name");
const getGSPs = GSP.selectAll("name");

const prodRepeatTables = createSelector(
	(state, ownProps) => parseInt(ownProps.match.params["id"]),
	(session, form) => {
		return session.ProjectRepeatableTable.filter(
			(s) => s.projectform_id === form
		)
			.orderBy("id", "desc")
			.toRefArray();
	}
);
const annualRepeatTables = createSelector(
	(state, ownProps) => parseInt(ownProps.match.params["id"]),
	(session, form) => {
		return session.AnnualRepeatableTable.filter(
			(s) => s.projectform_id === form
		)
			.orderBy("id", "desc")
			.toRefArray();
	}
);

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	helpText: {
		color: "#666666 !important",
		fontSize: "16px",
		fontWeight: "300",
		fontFamily: "'Source Sans Pro', sans-serif",
		lineHeight: "1",
		margin: "0px",
		marginBottom: "-8px",
	},
	deleteWidth: {
		minWidth: 34,
		width: 34,
		marginRight: 8,
	},
	table: {
		width: "100%",
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	minHeight: {
		minHeight: 500,
	},
	flex: {
		flex: 1,
	},
	floatLeft: {
		float: "left",
		marginLeft: 10,
	},
	floatRight: {
		float: "right",
		marginRight: 10,
	},
});

const projColumnData = [
	{ id: "actions", numeric: false, label: "Actions", allowSort: false },
	{
		id: "proj_date",
		numeric: false,
		label: "Status Update Date",
		allowSort: true,
	},
	{
		id: "project_status",
		numeric: false,
		label: "Status",
		allowSort: true,
	},
	{
		id: "project_notes",
		numeric: false,
		label: "Status Notes",
		allowSort: true,
	},
];
const annualColumnData = [
	{ id: "actions", numeric: false, label: "Actions", allowSort: false },
	{
		id: "water_year",
		numeric: false,
		label: "Water Year",
		allowSort: true,
	},
	{
		id: "annual_benefit",
		numeric: false,
		label: "Annual Benefit (Acre-Feet)",
		allowSort: true,
	},
	{
		id: "annual_cap_cost",
		numeric: false,
		label: "Annual Capital Cost",
		allowSort: true,
	},
	{
		id: "annual_op_cost",
		numeric: false,
		label: "Annual Operating Cost",
		allowSort: true,
	},
	{
		id: "proj_update_desc",
		numeric: false,
		label: "Update Description",
		allowSort: true,
	},
];

class EditProjects extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			callOptions: false,
			callOptions2: false,
			waterOptions: false,
			lat: null,
			lng: null,
			zoom: null,
			helpOpen: false,
			projid: null,
			deleteDialogOpen: false,
			repeattableid: null,
			projectDialogStatus: false,
			annualDialogStatus: false,
			deleteType: null,
		};

		this.is_new = false;
		if (props.match.params["id"] === "new") this.is_new = true;

		this.map = React.createRef();
	}
	componentDidUpdate() {
		clearTimeout(this.typingTimer);
		const { match } = this.props;
		const { projid } = this.state;
		if (!projid && match.params["id"] !== "new")
			//set projectform id for creating of repeatables
			this.setState({ projid: match.params["id"] });
	}
	componentDidMount() {
		const { authState, history, ormProjectFormCreate, match } = this.props;
		if (
			authState &&
			authState.user &&
			authState.user.role !== "Administrator"
		) {
			history.push("/dashboard");
		}
		if (match.params["id"] === "new") {
			ormProjectFormCreate({ source_gsa_id: 65, source_gsp_id: 11 }).then(
				(form) => {
					//change url for selectors if they create a repeatable record we need the url id
					history.push(`/adminprojects/${form}`);
				}
			);
		} else {
			//set the projform id for the repeatable tables
			this.setState({ projid: match.params["id"] });
		}
	}

	MAP_OPTIONS = (table) => {
		return table.map((row) => ({
			label: row.display_name,
			value: row.value,
		}));
	};

	MAKE_OPTIONS = (table) => {
		return table.map((row) => ({
			label: row.name,
			value: row.id,
		}));
	};

	MAKE_OPTIONS2 = (table) => {
		return table.map((row) => ({
			label: row.value,
			value: row.value,
		}));
	};

	updateLatLng(e) {
		const { projform, formApi } = this.props;

		var marker = e.target;
		var loc = marker.getLatLng();
		var map = marker._map;

		var zoom = map.getZoom();
		projform.geometry = {
			type: "Point",
			coordinates: [loc.lng, loc.lat],
		};

		this.setState({
			lat: loc.lat,
			lng: loc.lng,
			zoom: zoom,
		});

		//update fields as the marker was moved on the map
		this.formApi.setValue("lat", loc.lat);
		this.formApi.setValue("lon", loc.lng);
		this.formApi.setValue("geometry", {
			type: "Point",
			coordinates: [loc.lng, loc.lat],
		});
	}

	errorValidator = (values) => {
		const validateName = (name) => {
			return !name ? "Field is required" : null;
		};

		var valObj = {};
		valObj["source_gsa"] = validateName(values.source_gsa_id);
		valObj["source_gsp"] = validateName(values.source_gsp_id);

		return valObj;
	};

	handleRequestSort = (event, property, repeatTable) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		// we will sort ProdStatus repeatable table and Annual Update table
		var tableToSort = null;
		if (repeatTable === "Status") tableToSort = this.props.prodrepeattables;
		else tableToSort = this.props.annualrepeattables;

		//  null values break the sorting so set null values to ""
		tableToSort.forEach((s) => {
			if (s[orderBy] === null) {
				s[orderBy] = "";
			}
		});

		order === "desc"
			? tableToSort.sort((a, b) =>
					b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1
			  )
			: tableToSort.sort((a, b) =>
					a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1
			  );

		this.setState({ order, orderBy });
	};

	// handleChange = (evt) => {
	// 	this.setState({
	// 		[evt.target.name]: evt.target.value,
	// 	});
	// };

	defaultValue = () => {
		const { prodrepeattables, annualrepeattables } = this.props;
		const { repeattableid } = this.state;

		let obj = {};
		prodrepeattables.forEach((table) =>
			table.id === repeattableid ? (obj = table) : null
		);
		//ok wasnt from prod repeatable so look to annual
		if (JSON.stringify(obj) === "{}")
			annualrepeattables.forEach((table) =>
				table.id === repeattableid ? (obj = table) : null
			);

		return obj;
	};

	submit = (values, fromWhatForm) => {
		const {
			ormProjectFormUpdate,
			ormProjectRepeatableTableUpdate,
			ormAnnualRepeatableTableUpdate,
			history,
			authState,
		} = this.props;
		const { projid, repeattableid } = this.state;
		values.updateby_id = authState ? authState.user.id : null;

		if (fromWhatForm === "ProjectForm") {
			ormProjectFormUpdate({
				id: projid,
				...values,
			});
		} else if (fromWhatForm === "Status") {
			ormProjectRepeatableTableUpdate({
				id: repeattableid,
				...values,
			});
			setTimeout(() => {
				this.setState({ projectDialogStatus: false, repeattableid: null });
			}, 300);
		} else {
			ormAnnualRepeatableTableUpdate({
				id: repeattableid,
				...values,
			});
			setTimeout(() => {
				this.setState({ annualDialogStatus: false, repeattableid: null });
			}, 300);
		}

		if (fromWhatForm === "ProjectForm") history.push("/adminprojects");
	};

	render() {
		const {
			classes,
			history,
			match,
			gsas,
			gsp,
			projRepeatOptions,
			projOptions,
			projAnnualOptions,
			projform,
			prodrepeattables,
			annualrepeattables,
			ormProjectRepeatableTableDelete,
			ormAnnualRepeatableTableDelete,
		} = this.props;

		const {
			proFormChoices,
			proStatusChoices,
			proAnnualChoices,
			callOptions,
			callOptions2,
			waterOptions,
			lat,
			lng,
			helpOpen,
			deleteDialogOpen,
			repeattableid,
			projectDialogStatus,
			annualDialogStatus,
			deleteType,
		} = this.state;

		//OPTIONS comes back as a promise
		if (projOptions && !callOptions) {
			projOptions.then((data) => {
				this.setState({
					proFormChoices: data,
					callOptions: !callOptions,
				});
			});
		}
		if (projRepeatOptions && !callOptions2)
			projRepeatOptions.then((data) => {
				this.setState({
					proStatusChoices: data,
					callOptions2: !callOptions2,
				});
			});
		if (projAnnualOptions && !waterOptions)
			projAnnualOptions.then((data) => {
				this.setState({
					proAnnualChoices: data,
					waterOptions: !waterOptions,
				});
			});

		return (
			<AppContainer authenticated>
				<Grid container spacing={24}>
					<Grid item xs={12} className={classes.breadCrumb}>
						<BreadcrumbNav
							level1="Administration"
							level1link="/administration"
							level2="Manage Projects"
							level2link="/adminprojects"
							level3={(this.is_new ? "Add" : "Edit") + " Project"}
							level3link={"/adminwellsites/" + match.params["id"]}
						/>
					</Grid>
					<Grid item xs={12} md={9} lg={6} xl={3}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={() => history.push("/adminprojects")}>
							<Icon path={mdiUndo} size={1} color="white"></Icon>
							&nbsp;&nbsp;&nbsp; Return to Projects
						</Button>
					</Grid>

					<Form
						dontValidateOnMount="true"
						validateOnSubmit="true"
						defaultValues={projform}
						validateError={this.errorValidator}
						getApi={(el) => (this.formApi = el)}
						onSubmit={(values) => this.submit(values, "ProjectForm")}>
						{(formApi) => (
							<form onSubmit={formApi.submitForm}>
								<Grid item xs={12}>
									<ExpansionPanel defaultExpanded>
										<ExpansionPanelSummary
											classes={{ root: classes.expandedMargin }}
											expandIcon={<ExpandMoreIcon />}
											style={{ backgroundColor: "#ddd" }}>
											<ContactMail style={{ marginTop: 5 }} />
											<Grid container>
												<Grid xs={10} md={11}>
													<Typography style={{ paddingLeft: 32 }} variant="h6">
														Project Information
													</Typography>
												</Grid>
												<Grid item xs={2} md={1}>
													<Tooltip title="Save Form Fields">
														<Button
															variant="contained"
															type="submit"
															fullWidth
															color="primary"
															onClick={() => this.formApi.submitForm()}>
															Save
														</Button>
													</Tooltip>
												</Grid>
											</Grid>
										</ExpansionPanelSummary>

										<ExpansionPanelDetails>
											<Grid container spacing={16}>
												<Grid item xs={12} md={6} xl={4}>
													<TextField
														field="project_id"
														label="Project ID"
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} md={6} xl={4}>
													<TextField
														field="project_name"
														label="Project Name"
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} md={6} xl={4}>
													<>
														<Grid
															container
															alignItems="flex-end"
															className={classes.helpText}>
															GSA Name
															<HelpLabel
																open={helpOpen}
																question="Source GSA"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		GSA responsible for QCing and submiting data
																		for this Project
																	</>
																}
															/>
														</Grid>
														<Select
															field="source_gsa_id"
															options={this.MAKE_OPTIONS(gsas)}
															fullWidth
														/>
													</>
												</Grid>{" "}
												<Grid item xs={12} md={6} xl={4}>
													<>
														<Grid
															container
															alignItems="flex-end"
															className={classes.helpText}>
															GSP Name
															<HelpLabel
																open={helpOpen}
																question="Source GSP"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		GSP responsible for QCing and submiting data
																		for this Project
																	</>
																}
															/>
														</Grid>
														<Select
															field="source_gsp_id"
															options={this.MAKE_OPTIONS(gsp)}
															fullWidth
															required
														/>
													</>
												</Grid>
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<>
														<Grid
															container
															alignItems="flex-end"
															className={classes.helpText}>
															Record Type
															<HelpLabel
																open={helpOpen}
																question="Record Type"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Select the Record Type from the dropdown
																		list
																	</>
																}
															/>
														</Grid>
														{proFormChoices && (
															<Select
																field="record_type"
																options={this.MAKE_OPTIONS2(
																	proFormChoices.record_type.choices
																)}
																fullWidth
															/>
														)}
													</>
												</Grid>
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<>
														<Grid
															container
															alignItems="flex-end"
															className={classes.helpText}>
															Project Mechanism
															<HelpLabel
																open={helpOpen}
																question="Project Mechanism"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Select the Project Mechanism from the
																		dropdown list
																	</>
																}
															/>
														</Grid>
														{proFormChoices && (
															<Select
																field="project_mechanism"
																options={this.MAKE_OPTIONS2(
																	proFormChoices.project_mechanism.choices
																)}
																fullWidth
															/>
														)}
													</>
												</Grid>
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<TextField
														field="other_project_mechanism"
														label="Project Mechanism, If Other"
														fullWidth
														multiline
													/>
												</Grid>
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<TextField
														field="first_year"
														label="First Year Implemented"
														fullWidth
													/>
												</Grid>
												{/* <Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<>
														<Grid
															container
															alignItems="flex-end"
															className={classes.helpText}>
															Water Year Type{" "}
															<HelpLabel
																open={helpOpen}
																question="Water Year Type"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Select the Water Year from the dropdown list
																	</>
																}
															/>
														</Grid>
														{proFormChoices && (
															<Select
																field="water_year_type"
																options={this.MAKE_OPTIONS2(
																	proFormChoices.water_year_type.choices
																)}
																fullWidth
															/>
														)}
													</>
												</Grid> */}
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<TextField
														field="project_description"
														label="Project Description"
														// rows="3"
														multiline
														fullWidth
													/>
												</Grid>
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<TextField
														field="est_annual_benefit"
														label="Estimated Annual Benefit (Acre-Feet/Year)"
														fullWidth
													/>
												</Grid>
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<TextField
														field="est_cap_cost"
														label="Estimated Capital Cost ($)"
														useDollarFormat
														fullWidth
													/>
												</Grid>{" "}
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<TextField
														field="est_avg_ann_op_cost"
														label="Estimated Average Annual Operating Cost ($/Year)"
														useDollarFormat
														fullWidth
													/>
												</Grid>
												<Grid
													className={classes.expansionBorder}
													item
													xs={12}
													md={6}
													xl={4}>
													<TextField
														field="notes"
														label="Notes"
														// rows="3"
														fullWidth
														multiline
													/>
												</Grid>
											</Grid>
										</ExpansionPanelDetails>
									</ExpansionPanel>{" "}
									<ExpansionPanel defaultExpanded>
										<ExpansionPanelSummary
											classes={{ root: classes.expandedMargin }}
											expandIcon={<ExpandMoreIcon />}
											style={{ backgroundColor: "#ddd" }}>
											<RoomIcon style={{ marginTop: 5 }} />
											<Typography style={{ paddingLeft: 32 }} variant="h6">
												Project Location
											</Typography>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<Grid container xs={12}>
												<Grid xs={12}>
													<div style={{ height: "300px", width: "100%" }}>
														<EditMap
															ref={this.map}
															height={"300px"}
															width={"100%"}
															data={projform}
															handler={this.updateLatLng.bind(this)}
															geometry={projform.geometry}
															zoom={this.state.zoom}
														/>
													</div>
												</Grid>

												<Grid item xs={12} md={5}>
													<TextField
														field="lat"
														label="Latitude"
														eventHandle={(e) => {
															var _this = this;
															var loc = this.formApi.getValue("geometry");
															if (loc) loc.coordinates[1] = parseFloat(e); //intital render entry will be null so chk
															this.typingTimer = setTimeout(() => {
																if (
																	_this.map.current.props &&
																	_this.map.current.props.geometry
																)
																	_this.map.current.props.geometry.coordinates[1] =
																		parseFloat(e);
																if (loc) {
																	_this.formApi.setValue("geometry", loc); //set geo as hasnt been created yet
																	if (!_this.map.current.props.geometry)
																		_this.map.current.props.data.geometry = loc;
																} else
																	_this.formApi.setValue("geometry", {
																		//hasnt been created and entered in so we use state
																		type: "Point",
																		coordinates: [
																			_this.map.current.state.lng,
																			parseFloat(e),
																		],
																	});
															}, 1000);
														}}
														fullWidth
														placeholder={"Enter Coordinates or Move Map Marker"}
													/>
												</Grid>

												<Grid item xs={1}></Grid>
												<Grid item xs={12} md={5}>
													<TextField
														field="lon"
														label="Longitude"
														eventHandle={(e) => {
															var _this = this;
															var loc = this.formApi.getValue("geometry");
															if (loc) loc.coordinates[0] = parseFloat(e);

															this.typingTimer = setTimeout(() => {
																if (
																	_this.map.current &&
																	_this.map.current.props &&
																	_this.map.current.props.geometry
																)
																	_this.map.current.props.geometry.coordinates[0] =
																		parseFloat(e);
																if (loc) {
																	_this.formApi.setValue("geometry", loc);
																	if (!_this.map.current.props.geometry)
																		_this.map.current.props.data.geometry = loc;
																} else
																	_this.formApi.setValue("geometry", {
																		//hasnt been created and entered in so we use state
																		type: "Point",
																		coordinates: [
																			parseFloat(e),
																			_this.map.current.state.lat,
																		],
																	});
															}, 1000);
														}}
														fullWidth
														placeholder={"Enter Coordinates or Move Map Marker"}
													/>
												</Grid>
											</Grid>
										</ExpansionPanelDetails>
									</ExpansionPanel>
									<ExpansionPanel defaultExpanded>
										<ExpansionPanelSummary
											classes={{ root: classes.expandedMargin }}
											expandIcon={<ExpandMoreIcon />}
											style={{ backgroundColor: "#ddd" }}>
											<AddAlarmIcon style={{ marginTop: 3 }} />
											<Typography style={{ paddingLeft: 32 }} variant="h6">
												Project Status
											</Typography>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<Grid container>
												<Grid item xs={8} xl={10}></Grid>
												<Grid item xs={4} xl={2} style={{ marginTop: 20 }}>
													<Button
														color="primary"
														variant="contained"
														fullWidth
														onClick={() => {
															const { ormProjectRepeatableTableCreate } =
																this.props;
															const { projid } = this.state;
															if (projid)
																ormProjectRepeatableTableCreate({
																	projectform_id: parseInt(projid),
																}).then((repeatid) => {
																	this.setState((state) => ({
																		repeattableid: repeatid,
																		projectDialogStatus: true,
																	}));
																});
														}}>
														<Icon path={mdiPlus} size={1} color="white"></Icon>
														&nbsp;&nbsp;&nbsp; Add New Status
													</Button>
												</Grid>
												<Grid item xs={12} style={{ marginTop: 20 }}>
													<Table className={classes.table}>
														<EnhancedTableHead
															columnData={projColumnData}
															// order={order}
															// orderBy={orderBy}
															onRequestSort={(event, property) =>
																this.handleRequestSort(
																	event,
																	property,
																	"Status"
																)
															}
														/>
														<TableBody>
															{prodrepeattables &&
																prodrepeattables.length > 0 &&
																prodrepeattables
																	// .slice(
																	// 	page * rowsPerPage,
																	// 	page * rowsPerPage + rowsPerPage
																	// )
																	.map((item) => (
																		<TableRow key={item.id}>
																			<TableCell className={classes.actionCell}>
																				<Tooltip title="Edit Status">
																					<Button
																						className={classes.deleteWidth}
																						onClick={() => {
																							this.setState({
																								projectDialogStatus: true,
																								repeattableid: item.id,
																							});
																						}}>
																						<EditIcon color="primary" />
																					</Button>
																				</Tooltip>
																				<Tooltip title="Delete Status">
																					<Button
																						className={classes.deleteWidth}
																						onClick={() =>
																							this.setState({
																								deleteDialogOpen: true,
																								repeattableid: item.id,
																								deleteType: "Project",
																							})
																						}>
																						<DeleteIcon
																							style={{ color: "#b20000" }}
																						/>
																					</Button>
																				</Tooltip>
																			</TableCell>
																			<TableCell>
																				{item.project_status}
																			</TableCell>
																			<TableCell>{item.proj_date}</TableCell>
																			<TableCell>
																				{item.project_notes}
																			</TableCell>
																		</TableRow>
																	))}
															{/* {prodRepeatTables.length < 1 && (
															<TableRow>
																<TableCell
																	colSpan={4}
																	className={classes.centerAlign}>
																	No Status Found
																</TableCell>
															</TableRow>
														)} */}
														</TableBody>
														{/* {filtered_items.length > 25 && (
								<TableFooter>
									<TableRow>
										<TablePagination
											colSpan={4}
											count={filtered_items.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={this.handleChangePage}
											onChangeRowsPerPage={this.handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActionsWrapped}
										/>
									</TableRow>
								</TableFooter>
							)} */}
													</Table>
												</Grid>
											</Grid>
										</ExpansionPanelDetails>
									</ExpansionPanel>{" "}
									<ExpansionPanel defaultExpanded>
										<ExpansionPanelSummary
											classes={{ root: classes.expandedMargin }}
											expandIcon={<ExpandMoreIcon />}
											style={{ backgroundColor: "#ddd" }}>
											<AssignmentTurnedInIcon style={{ marginTop: 3 }} />
											<Typography style={{ paddingLeft: 32 }} variant="h6">
												Annual Update
											</Typography>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<Grid container>
												<Grid item xs={8} xl={10}></Grid>
												<Grid item xs={4} xl={2} style={{ marginTop: 20 }}>
													<Button
														color="primary"
														variant="contained"
														fullWidth
														onClick={
															() => {
																const { ormAnnualRepeatableTableCreate } =
																	this.props;
																const { projid } = this.state;
																if (projid)
																	ormAnnualRepeatableTableCreate({
																		projectform_id: parseInt(projid),
																	}).then((repeatid) => {
																		this.setState((state) => ({
																			repeattableid: repeatid,
																			annualDialogStatus: true,
																		}));
																	});
															} /* this.createNewStatus()*/
														}>
														<Icon path={mdiPlus} size={1} color="white"></Icon>
														&nbsp;&nbsp;&nbsp; Add Annual Update
													</Button>
												</Grid>
												<Grid item xs={12} style={{ marginTop: 20 }}>
													<Table className={classes.table}>
														<EnhancedTableHead
															columnData={annualColumnData}
															// order={order}
															// orderBy={orderBy}
															onRequestSort={(event, property) =>
																this.handleRequestSort(
																	event,
																	property,
																	"Annual"
																)
															}
														/>
														<TableBody>
															{annualrepeattables &&
																annualrepeattables.length > 0 &&
																annualrepeattables
																	// .slice(
																	// 	page * rowsPerPage,
																	// 	page * rowsPerPage + rowsPerPage
																	// )
																	.map((item) => (
																		<TableRow key={item.id}>
																			<TableCell className={classes.actionCell}>
																				<Tooltip title="Edit Annual Status">
																					<Button
																						className={classes.deleteWidth}
																						onClick={() => {
																							this.setState({
																								annualDialogStatus: true,
																								repeattableid: item.id,
																							});
																						}}>
																						<EditIcon color="primary" />
																					</Button>
																				</Tooltip>
																				<Tooltip title="Delete Annual Status">
																					<Button
																						className={classes.deleteWidth}
																						onClick={() =>
																							this.setState({
																								deleteDialogOpen: true,
																								repeattableid: item.id,
																								deleteType: "Annual",
																							})
																						}>
																						<DeleteIcon
																							style={{ color: "#b20000" }}
																						/>
																					</Button>
																				</Tooltip>
																			</TableCell>
																			<TableCell>{item.water_year}</TableCell>
																			<TableCell>
																				{item.annual_benefit}
																			</TableCell>
																			<TableCell>
																				<NumberFormat
																					displayType={"text"}
																					value={item.annual_cap_cost}
																					prefix="$"
																					decimalScale={2}
																					thousandSeparator={true}
																				/>
																			</TableCell>
																			<TableCell>
																				<NumberFormat
																					displayType={"text"}
																					value={item.annual_op_cost}
																					prefix="$"
																					decimalScale={2}
																					thousandSeparator={true}
																				/>
																			</TableCell>
																			<TableCell>
																				{item.proj_update_desc}
																			</TableCell>
																		</TableRow>
																	))}
															{/* {prodRepeatTables.length < 1 && (
															<TableRow>
																<TableCell
																	colSpan={4}
																	className={classes.centerAlign}>
																	No Status Found
																</TableCell>
															</TableRow>
														)} */}
														</TableBody>
														{/* {filtered_items.length > 25 && (
								<TableFooter>
									<TableRow>
										<TablePagination
											colSpan={4}
											count={filtered_items.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={this.handleChangePage}
											onChangeRowsPerPage={this.handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActionsWrapped}
										/>
									</TableRow>
								</TableFooter>
							)} */}
													</Table>
												</Grid>
											</Grid>
										</ExpansionPanelDetails>
									</ExpansionPanel>
									<Grid
										container
										spacing={40}
										alignItems="center"
										justify="center">
										<Grid item xs={8}>
											<Tooltip title="Save Form Fields">
												<Button
													variant="contained"
													type="submit"
													fullWidth
													color="primary"
													onClick={() => this.formApi.submitForm()}>
													Save
												</Button>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
							</form>
						)}
					</Form>
				</Grid>
				<Dialog
					open={projectDialogStatus}
					className={{ root: classes.minHeight }}>
					<Toolbar style={{ backgroundColor: "#d3d3d3" }}>
						<Typography variant="h5" className={classes.flex}>
							Add New Status
						</Typography>
						<IconButton
							aria-label="Close Dialog"
							onClick={() => {
								this.setState({ projectDialogStatus: false });
							}}>
							<Close />
						</IconButton>
					</Toolbar>
					<DialogContent>
						<DialogContentText>
							<Form
								dontValidateOnMount={true}
								defaultValues={this.defaultValue()}
								getApi={(el) => (this.formApi2 = el)}
								onSubmit={(values) => this.submit(values, "Status")}>
								{(formApi2) => (
									<form onSubmit={formApi2.submitForm}>
										<Grid container spacing={2} style={{ paddingTop: 16 }}>
											{prodrepeattables &&
												prodrepeattables.map((table) => {
													if (table.id === repeattableid)
														return (
															<>
																<Grid item xs={12}>
																	<TextField
																		field="proj_date"
																		label="Status Update Date"
																		type="date"
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	{proStatusChoices && (
																		<Select
																			field="project_status"
																			label="Status"
																			options={this.MAP_OPTIONS(
																				proStatusChoices.project_status.choices
																			)}
																			fullWidth
																		/>
																	)}
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		field="project_notes"
																		label="Status Notes"
																		rows="3"
																		multiline
																		fullWidth
																	/>
																</Grid>
															</>
														);
												})}

											<Grid xs={12}>
												<div style={{ marginTop: 22 }}>
													<Button
														onClick={(event) => {
															// event.stopPropagation();
															this.formApi2.submitForm();
														}}
														// type="submit"
														variant="raised"
														color="primary"
														className={classes.floatRight}>
														Save Record
													</Button>
													<Button
														onClick={() => {
															return this.setState({
																deleteDialogOpen: true,
																repeattableid,
																projectDialogStatus: false,
																deleteType: "Project",
															});
														}}
														variant="raised"
														color="primary"
														className={classes.floatLeft}>
														Delete Record
													</Button>
												</div>
											</Grid>
										</Grid>
									</form>
								)}
							</Form>
						</DialogContentText>
					</DialogContent>
				</Dialog>
				<Dialog
					open={annualDialogStatus}
					className={{ root: classes.minHeight }}>
					<Toolbar style={{ backgroundColor: "#d3d3d3" }}>
						<Typography variant="h5" className={classes.flex}>
							Add New Annual Status
						</Typography>
						<IconButton
							aria-label="Close Dialog"
							onClick={() => {
								this.setState({ annualDialogStatus: false });
							}}>
							<Close />
						</IconButton>
					</Toolbar>
					<DialogContent>
						<DialogContentText>
							<Form
								dontValidateOnMount={true}
								defaultValues={this.defaultValue()}
								getApi={(el) => (this.formApi3 = el)}
								onSubmit={(values) => this.submit(values, "Annual")}>
								{(formApi3) => (
									<form onSubmit={formApi3.submitForm}>
										<Grid container spacing={2} style={{ paddingTop: 16 }}>
											{annualrepeattables &&
												annualrepeattables.map((table) => {
													if (table.id === repeattableid)
														return (
															<>
																<Grid item xs={12}>
																	{proAnnualChoices && (
																		<Select
																			field="water_year"
																			label="Water Year"
																			options={this.MAP_OPTIONS(
																				proAnnualChoices.water_year.choices
																			)}
																			fullWidth
																		/>
																	)}
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		field="annual_benefit"
																		label="Annual Benefit (Acre-Feet)"
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		field="annual_cap_cost"
																		label="Annual Capital Cost"
																		useDollarFormat
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		field="annual_op_cost"
																		label="Annual Operating Cost"
																		useDollarFormat
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		field="proj_update_desc"
																		label="Project Update Description"
																		minRows={3}
																		multiline
																		fullWidth
																	/>
																</Grid>
															</>
														);
												})}

											<Grid xs={12}>
												<div style={{ marginTop: 22 }}>
													<Button
														onClick={(event) => {
															// event.stopPropagation();
															this.formApi3.submitForm();
														}}
														// type="submit"
														variant="raised"
														color="primary"
														className={classes.floatRight}>
														Save Record
													</Button>
													<Button
														onClick={() => {
															return this.setState({
																deleteDialogOpen: true,
																repeattableid,
																annualDialogStatus: false,
																deleteType: "Annual",
															});
														}}
														variant="raised"
														color="primary"
														className={classes.floatLeft}>
														Delete Record
													</Button>
												</div>
											</Grid>
										</Grid>
									</form>
								)}
							</Form>
						</DialogContentText>
					</DialogContent>
				</Dialog>
				<WarningDialog
					confirmAction={() => {
						if (deleteType === "Project")
							ormProjectRepeatableTableDelete(repeattableid);
						if (deleteType === "Annual")
							ormAnnualRepeatableTableDelete(repeattableid);
						setTimeout(() => {
							this.setState({
								deleteDialogOpen: false,
								repeattableid: null,
								deleteType: null,
							});
						}, 300);
					}}
					cancelAction={() => this.setState({ deleteDialogOpen: false })}
					open={deleteDialogOpen}
					title="Delete Record"
					text={"Are you sure you want to delete?"}
				/>
			</AppContainer>
		);
	}
}

EditProjects = connect(
	(state, ownProps) => ({
		projform: projForm(state, ownProps),
		prodrepeattables: prodRepeatTables(state, ownProps),
		annualrepeattables: annualRepeatTables(state, ownProps),
		projOptions: getProjOptions(state, ownProps),
		projRepeatOptions: getProjRepeatOptions(state, ownProps),
		projAnnualOptions: getWaterOptions(state, ownProps),
		gsas: getGSAs(state, ownProps),
		gsp: getGSPs(state, ownProps),
		authState: state.auth,
	}),
	{
		...ProjectForm.actions,
		...ProjectRepeatableTable.actions,
		...AnnualRepeatableTable.actions,
	}
)(EditProjects);

export default withStyles(styles)(withRouter(EditProjects));
