import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
// import TextField from "@material-ui/core/TextField";
import TextField from "../common/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "../common/Select";
// import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";
// import { BlueOnClick } from "../common/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import BreadcrumbNav from "../common/BreadCrumb";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TotalWaterUse, GroundwaterRecharge } from "./models";
import { createSelector } from "../common/orm";
import HelpLabel from "../common/HelpLabel";
import NumberFormat from "react-number-format";
/* Calc Method Start */
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Form } from "react-form";

const getRechargeData = createSelector(
	(state, ownProps) => ({
		id: parseInt(ownProps.match.params["id"]),
	}),
	(session, obj) => {
		return session.GroundwaterRecharge.filter(
			(u) => u.id === obj.id
		).toRefArray()[0];
	}
);
// const getOptions = GroundwaterRecharge.getOptions();

const CalcMethod = (props) => {
	// props: fieldName, currentValue
	const [value, setValue] = React.useState(props.currentValue); // eslint-disable-line no-unused-vars

	/* TODO: Figure out how to set the field's value so that the value of the field and make sure the form can read it */
	return (
		<FormControl component="fieldset">
			<RadioGroup
				style={{ flexWrap: "nowrap", marginLeft: "10px" }}
				aria-label="direct"
				name={props.fieldName}
				row>
				<FormControlLabel
					value="Direct"
					disabled={props.disabled}
					onChange={props.onChange}
					style={{ alignItems: "center" }}
					control={
						<Radio
							checked={props.value === "Direct"}
							style={{
								padding: "6px 0px 3px 7px",
								color: props.value === "Direct" ? "primary" : "",
							}}
						/>
					}
					label="Direct"
					labelPlacement="end"
				/>
				<FormControlLabel
					value="Estimated"
					disabled={props.disabled}
					onChange={props.onChange}
					style={{ alignItems: "center" }}
					control={
						<Radio
							checked={props.value === "Estimated"}
							style={{
								padding: "6px 0 3px 7px",
								color: props.value === "Estimated" ? "primary" : "",
							}}
						/>
					}
					label="Estimated"
					labelPlacement="end"
				/>
			</RadioGroup>
		</FormControl>
	);
};
/* Calc Method End */

const getUserData = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let user = session.User.filter((u) => u.id === options.id).toRefArray()[0];

		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		return user && association && gsp
			? {
					...user,
					association: association ? association : {},
					gsp: gsp ? gsp : {},
			  }
			: {};
	}
);
//eslint-disable-next-line
function formatDate(date) {
	var monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	var day = date.getUTCDate();
	var monthIndex = date.getUTCMonth();
	var year = date.getUTCFullYear();

	return monthNames[monthIndex] + " " + day + ", " + year;
}

const getWaterYear = createSelector((session, id) => {
	let wy = session.WaterYear.filter(
		(y) => y.is_active === true
	).toRefArray()[0];
	return {
		...wy,
	};
});

const currentTotalWaterUse = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gwre"]),
		twu_id: parseInt(ownProps.match.params["id"]),
	}),
	(session, options) => {
		let gsp = session.GSP.filter((u) => u.id === options.gsp).toRefArray()[0];

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		if (options.twu_id) {
			let twu = session.GroundwaterRecharge.filter(
				(y) => y.gsp_name_id === gsp.id && y.id === options.twu_id
			).toRefArray()[0];

			let wy =
				twu && twu.water_year_id
					? session.WaterYear.filter(
							(y) => y.id === twu.water_year_id
					  ).toRefArray()[0]
					: {};

			twu = { ...twu, wy: wy };

			return gsp && twu && wy ? twu : {};
		} else {
			return gsp && thisWaterYear
				? session.GroundwaterRecharge.filter(
						(y) => y.water_year_id === thisWaterYear.id && y.gsp === gsp.id
				  ).toRefArray()[0]
				: {};
		}
	}
);

const historicTotalWaterUse = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		return thisWaterYear && gsp
			? session.TotalWaterUse.filter(
					(y) => y.water_year_id !== thisWaterYear.id && y.gsp === gsp.id
			  ).toRefArray()
			: {};
	}
);

const styles = (theme) => ({
	container: {
		paddingLeft: "10px",
	},
	hrclass: {
		borderColor: "primary",
	},
	tc: {
		padding: "4px 4px 4px 4px",
		maxWidth: "60px",
	},
	tcNumber: {
		padding: "4px 4px 4px 4px",
		maxWidth: "40px",
	},
	rightAligned: {
		textAlign: "right",
		padding: "4px 4px 4px 4px",
		maxWidth: "40px",
	},
	tcAccuracy: {
		padding: "4px 4px 4px 15px",
		maxWidth: "20px",
	},
	mdHeader: {
		fontSize: "18px",
		textDecoration: "underline",
		fontWeight: "normal",
	},
	lgHeader: {
		fontSize: "25px",
	},
	buttonActive: {
		backgroundColor: theme.palette.primary.dark + " !important",
		color: theme.palette.primary.contrastText + " !important",
	},
	buttonInactive: {
		backgroundColor: theme.palette.primary.light + " !important",
		color: theme.palette.primary.contrastText + " !important",
	},
});

class ReportingGWRecharge extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accuracyChoices: [
				{ label: "Unknown" },
				{ label: "Other" },
				{ label: "0-5%" },
				{ label: "5-10%" },
				{ label: "10-20%" },
				{ label: "20-30%" },
				{ label: "30-40%" },
				{ label: "40-50%" },
				{ label: "50-60%" },
				{ label: "60-70%" },
				{ label: "70-80%" },
				{ label: "80-90%" },
			],
			calcMethod: [
				{ label: "Direct" },
				{ label: "Estimated" },
				{ label: "Unknown" },
			],
			csrftoken: localStorage.auth_token,
			loader: null,
			agency: "Not Available",
			waterYearStart: "Not Available",
			waterYearEnd: "Not Available",
			districtName: "Not Available",
			submitted: false,
			saved: false,
			authorized: false,
			helpOpen: false,
			callOptions: false,

			water_year_id: null,
			gsp_id: null,
			curdata: false,
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}

	//Check for: user && user.role === "GSP Representative" ?

	// populateStateFromHistory = () => {
	// 	const { watercur, user } = this.props;

	// 	if (watercur && watercur.id && !this.state.curdata) {
	// 		let isAuth = false;
	// 		if (
	// 			user &&
	// 			user.association &&
	// 			user.association.authorized_reporter &&
	// 			user &&
	// 			user.role === "GSP Representative"
	// 		) {
	// 			isAuth = true;
	// 		}

	// 		if (watercur.submitted || isAuth) {
	// 			this.setState({
	// 				...this.state,
	// 				...watercur,
	// 				curdata: true,
	// 				authorized: isAuth,
	// 			});
	// 		}
	// 	}
	// };

	setAuthorized = () => {
		const { user } = this.props;
		const { authorized } = this.state;

		if (user && user.association && user.association.authorized_reporter) {
			if (!authorized && user && user.role === "GSP Representative") {
				this.setState({ authorized: true });
			}
		}
	};

	pf = (val) => {
		if (val === "-" || val === "" || val === " ") {
			return parseFloat(0);
		} else if (typeof val === "string") {
			return parseFloat(val.replace(/,/g, ""));
		} else {
			return parseFloat(val);
		}
	};

	// NumberFormatCustom = (props) => {
	// 	const { inputRef, onChange, ...other } = props;

	// 	return (
	// 		<NumberFormat
	// 			{...other}
	// 			getInputRef={inputRef}
	// 			onValueChange={(values) => {
	// 				onChange({
	// 					target: {
	// 						value: values.value,
	// 						name: other.name,
	// 					},
	// 				});
	// 			}}
	// 			thousandSeparator
	// 		/>
	// 	);
	// };

	submit = (evt) => {
		evt.preventDefault();
		const { ormTotalWaterUseCreate, ormTotalWaterUseUpdate } = this.props;
		const { user, wateryr, history } = this.props;
		const { curdata } = this.state;

		this.setState({
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		});

		const stObj = {
			...this.state,
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		};

		if (!curdata) {
			ormTotalWaterUseCreate(stObj);
		} else {
			ormTotalWaterUseUpdate(stObj);
		}

		history.push("/reporting");
	};

	submitForm = (evt) => {
		const { ormGroundwaterRechargeUpdate, history } = this.props;

		this.setState({
			submitted: true,
		});

		// const stObj = {
		// 	...this.state,
		// 	submitted: true,
		// };

		ormGroundwaterRechargeUpdate(evt);

		history.push("/reporting");
	};

	defaultValues = (id) => {
		const { rechargeData } = this.props;

		//get all fields for to check for autopopulate on date
		let defaultValue = {};
		return rechargeData ? rechargeData : defaultValue;
	};

	render() {
		this.setAuthorized();

		// this.populateStateFromHistory();

		const {
			submitted,
			saved,

			authorized,
			helpOpen,
			siteChoices,
			accuracyChoices,
			calcMethod,
		} = this.state;
		const {
			classes,
			user,
			wateryr,
			watercur,
			waterhist,
			rechargeData /* optionsReq*/,
		} = this.props;

		let submitActive =
			(saved || this.state.curdata) && !submitted && authorized;

		//OPTIONS comes back as a promise
		// if (optionsReq && !siteChoices) {
		// 	optionsReq.then((data) => {
		// 		if (!this.state.siteChoices) {
		// 			this.setState({
		// 				siteChoices: data,
		// 			});
		// 		}
		// 	});
		// }
		function GET_OPTIONS(select) {
			return select.map((s) => ({
				label: s.label,
				value: s.label,
			}));
		}

		return (
			<AppContainer authenticated>
				<div className={classes.container}>
					<Form
						key={rechargeData && rechargeData.id}
						dontValidateOnMount="false"
						getApi={(el) => (this.formApi = el)}
						// validateOnSubmit="true"
						defaultValues={this.defaultValues()}
						// validateError={this.errorValidator}
						onSubmit={(values, fromStepper, formApi) =>
							this.submitForm(values, fromStepper, formApi)
						}>
						{(formApi) => (
							<form onSubmit={formApi.submitForm}>
								{watercur && watercur.wy ? (
									<>
										<BreadcrumbNav
											level1="GW Recharge Data"
											level1link="/gwrecharge"
											level2="GW Recharge Details"
											level2link={
												"/reporting/gwrecharge/" +
												this.props.match.params["gwre"] +
												"/" +
												this.props.match.params["id"]
											}
										/>
										<br />
									</>
								) : (
									<>
										<BreadcrumbNav
											level1="Associated GSP's"
											level1link="/reporting"
											level2="Report GW Recharge Data"
											level2link={
												"/reporting/gwrecharge/" +
												this.props.match.params["gwre"]
											}
										/>
										<br />
									</>
								)}
								<Grid container spacing={24}>
									<Grid item xs={12}>
										{watercur && (
											<>
											   <span className={classes.lgHeader}>
												Water Year:{" "}
												{watercur.water_year}{" "}
												/ Measurement Month:{" "}
												{watercur.month}
												
												</span>
											</>
										)} 
										{/* // : (
										// 	<>
										// 		<hr className={classes.hrclass} />
										// 		<span className={classes.lgHeader}>
										// 			Current Water Year {wateryr ? wateryr.water_year : ""}
										// 			:{" "}
										// 			{wateryr
										// 				? new Date(
										// 						wateryr.water_year_start + " "
										// 				  ).toLocaleDateString("en-US")
										// 				: ""}{" "}
										// 			-{" "}
										// 			{wateryr
										// 				? new Date(
										// 						wateryr.water_year_end + " "
										// 				  ).toLocaleDateString("en-US")
										// 				: ""}
										// 		</span>
										// 	</>
										// )} */}
									</Grid>
									{watercur && watercur.gsp_name_label && (
										<Grid item xs={12}>
										<>
											<span>
												<b>GSP:</b> {watercur.gsp_name_label}  
											</span>
											<span style={{marginLeft:4}}>
												{"  |  "}<b> GSA: </b>{watercur.gsa_name_label}
											</span>
										</>
										</Grid>
									)}
									{watercur && watercur.wy ? (
										<></>
									) : (
										<>
											<Grid item xs={12}>
												<b>Role:</b> Your user account is{" "}
												{authorized ? "currently" : <b>NOT</b>} authorized to
												report data for:{" "}
												<b>
													{user && user.gsp
														? user.gsp.name
														: "(User not associated with GSP)"}
												</b>
												.
											</Grid>
											<Grid item xs={12}>
												<b>Status:</b> {submitted ? "Data" : "No data"} has been
												imported into the DMS for the current water year.{" "}
												{waterhist && waterhist.length && user && user.gsp ? (
													<a
														href={
															"/#/reportinghistory/totalwateruse/" +
															+this.props.match.params["gsp"]
														}>
														View Historic Data
													</a>
												) : (
													""
												)}
											</Grid>
										</>
									)}
									<hr />
									<Grid item xs={12}>
										<>
											<hr className={classes.hrclass} />
											<h2 className={classes.mdHeader}>
												Ground Water Recharge Data
												<HelpLabel
													open={helpOpen}
													question="Ground Water Recharge"
													showHelp={true}
													inputLabel={true}
													helpText={
														<>
															Volume of water recharged by each water use
															sector. This volume may be equal to or less than
															the volume of groundwater or surface water
															supplies delivered. A water recharge volume less
															than the volume of supplies delivered may indicate
															a loss of water during delivery and/or use of
															precipitation. Estimated water use shall consider
															all water sources available, including
															precipitation, surface water seepage, and passive
															groundwater use.
														</>
													}
												/>
											</h2>

											<Table>
												<TableBody>
													<TableRow>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={2}
															className={classes.tcNumber}>
															Water Use Sector
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Infiltration of Applied Water
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Infiltration of Percipitation
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Other
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Other Description
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Total
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Source Notes
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Calculation Method
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcAccuracy}>
															Calculation Accuracy
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell xs={2} className={classes.tcNumber}>
															Urban/Domestic:
															<HelpLabel
																open={helpOpen}
																question="Urban/Domestic Water Use"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Municipal, domestic, commercial and/or
																		institutional water use, either a metered
																		delivery or an estimate of water delivered.
																		Note that industrial water recharge is
																		reported as a separate category.
																	</>
																}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_appwater"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_precip"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_other"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>{" "}
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_other_desc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_total"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_src_notes"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_calcm"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}>
																{GET_OPTIONS(calcMethod).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"dom_calc_acc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4">
																{GET_OPTIONS(accuracyChoices).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell xs={2} className={classes.tcNumber}>
															Agricultural:
															<HelpLabel
																open={helpOpen}
																question="Agricultural Water Use"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Municipal, domestic, commercial and/or
																		institutional water use, either a metered
																		delivery or an estimate of water delivered.
																		Note that industrial water recharge is
																		reported as a separate category.
																	</>
																}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_appwater"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_precip"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_other"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>{" "}
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_other_desc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_total"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_src_notes"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_calcm"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}>
																{GET_OPTIONS(calcMethod).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"agg_calc_acc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4">
																{GET_OPTIONS(accuracyChoices).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell xs={2} className={classes.tcNumber}>
															Managed Recharge:
															<HelpLabel
																open={helpOpen}
																question="Managed Recharge Water Use"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Municipal, domestic, commercial and/or
																		institutional water use, either a metered
																		delivery or an estimate of water delivered.
																		Note that industrial water recharge is
																		reported as a separate category.
																	</>
																}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_appwater"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_precip"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_other"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>{" "}
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_other_desc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_total"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_src_notes"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_calcm"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}>
																{GET_OPTIONS(calcMethod).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"man_calc_acc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4">
																{GET_OPTIONS(accuracyChoices).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell xs={2} className={classes.tcNumber}>
															Native Vegetation:
															<HelpLabel
																open={helpOpen}
																question="Native Vegetation Water Use"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Municipal, domestic, commercial and/or
																		institutional water use, either a metered
																		delivery or an estimate of water delivered.
																		Note that industrial water recharge is
																		reported as a separate category.
																	</>
																}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_appwater"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_precip"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_other"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>{" "}
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_other_desc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_total"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_src_notes"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_calcm"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}>
																{GET_OPTIONS(calcMethod).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"nat_calc_acc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4">
																{GET_OPTIONS(accuracyChoices).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
													</TableRow>
													<TableRow />
													<TableRow>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={2}
															className={classes.tcNumber}>
															Water Use Sector
														</TableCell>{" "}
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Sector name or Description
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Infiltration of Applied Water
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Infiltration of Percipitation
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Other
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Other Description
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Total
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Source Notes
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Calculation Method
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcAccuracy}>
															Calculation Accuracy
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell xs={2} className={classes.tcNumber}>
															Other water use:
															<HelpLabel
																open={helpOpen}
																question="Native Vegetation Water Use"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Municipal, domestic, commercial and/or
																		institutional water use, either a metered
																		delivery or an estimate of water delivered.
																		Note that industrial water recharge is
																		reported as a separate category.
																	</>
																}
															/>
														</TableCell>{" "}
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_name"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_appwater"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_precip"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_other"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>{" "}
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_other_desc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_total"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_src_notes"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_calcm"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}>
																{GET_OPTIONS(calcMethod).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"other_calc_acc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax={"4"}>
																{GET_OPTIONS(accuracyChoices).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={2}
															className={classes.tcNumber}>
															Water Use Sector
														</TableCell>{" "}
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Canals/Conveyance
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Rivers and Streams
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Boundary Rivers and Streams
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Other
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Other Description
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcNumber}>
															Total
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Source Notes
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tc}>
															Calculation Method
														</TableCell>
														<TableCell
															style={{ backgroundColor: "#e1f9ff" }}
															xs={1}
															className={classes.tcAccuracy}>
															Calculation Accuracy
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell xs={2} className={classes.tcNumber}>
															Surface Water System:
															<HelpLabel
																open={helpOpen}
																question="Surface Water System Use"
																showHelp={true}
																inputLabel={true}
																helpText={
																	<>
																		Municipal, domestic, commercial and/or
																		institutional water use, either a metered
																		delivery or an estimate of water delivered.
																		Note that industrial water recharge is
																		reported as a separate category.
																	</>
																}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_canals"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_rivers"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_boundary"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>{" "}
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_other"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_other_desc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_total"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			AF
																		</InputAdornment>
																	),
																	inputComponent: this.NumberFormatCustom,
																}}
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_src_notes"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}
																multiline={true}
																rows={1}
																rowsMax="4"
															/>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_calcm"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}>
																{GET_OPTIONS(calcMethod).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
														<TableCell xs={1} className={classes.tcNumber}>
															<TextField
																field={"sur_calc_acc"}
																disabled={
																	rechargeData
																		? rechargeData.submitted || !authorized
																		: null
																}
																select
																fullWidth
																InputProps={{
																	inputComponent: this.NumberFormatCustom,
																}}>
																{GET_OPTIONS(accuracyChoices).map((o, i) => (
																	<MenuItem key={i} value={o.label}>
																		{o.label}
																	</MenuItem>
																))}
															</TextField>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</>
									</Grid>
									{watercur && watercur.wy ? (
										<></>
									) : (
										<>
											<Grid item xs={3}>
												<Button
													fullWidth
													variant="contained"
													type="submit"
													label="Save"
													// disabled={submitted || !authorized}
													onClick={() => this.formApi.submitForm()}
													className={
														!submitted && authorized
															? classes.buttonActive
															: classes.buttonInactive
													}>
													Save
												</Button>
											</Grid>
											<Grid item xs={9}>
												<Button
													type="button"
													// onClick={ }
													disabled={!submitActive}
													className={
														submitActive
															? classes.buttonActive
															: classes.buttonInactive
													}>
													Submit Data as Final for Current Reporting Water Year
												</Button>
											</Grid>
										</>
									)}
								</Grid>{" "}
							</form>
						)}
					</Form>
				</div>
			</AppContainer>
		);
	}
}
ReportingGWRecharge = connect(
	(state, ownProps) => ({
		wateryr: getWaterYear(state, ownProps),
		waterhist: historicTotalWaterUse(state, ownProps),
		authState: state.auth,
		rechargeData: getRechargeData(state, ownProps),
		// optionsReq: getOptions(state, ownProps),
		// user: getUserData(state, ownProps),
		watercur: currentTotalWaterUse(state, ownProps),
	}),
	{
		...GroundwaterRecharge.actions,
		...authActions,
		...navActions,
	}
)(ReportingGWRecharge);
export default withStyles(styles)(withRouter(ReportingGWRecharge));
