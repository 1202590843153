import React, { Component } from "react";
import { connect } from "react-redux";
// import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import NotReady from "../common/NotReady";
import ReportingStatus from "../../api/reportingStatusHOC";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

import Icon from "@mdi/react";
import { mdiEye, mdiPencilOutline } from "@mdi/js";

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	tableCellIcon: {
		minWidth: 34,
		width: 34,
		marginRight: 0,
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		width: "100%",
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	lgHeader: {
		fontSize: "25px",
	},
});

const columnData = [
	{
		id: "name",
		numeric: false,
		label: "GSP Name",
		allowSort: true,
	},
	{
		id: "upload",
		numeric: false,
		label: "Groundwater Storage Data",
		allowSort: false,
	},
	{
		id: "gw_submitted",
		numeric: false,
		label: "Groundwater (Extraction) Data",
		allowSort: true,
	},
	{
		id: "surf_submitted",
		numeric: false,
		label: "Surface Water Supply Data",
		allowSort: true,
	},
	{
		id: "total_submitted",
		numeric: false,
		label: "Total Water Use Data",
		allowSort: true,
	},
];

class ReportingGSPs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: "desc",
			orderBy: "water_year",
			page: 0,
			rowsPerPage: 10,
			authorized: false,
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}

	handleRequestSort = (event, property) => {
		const { existingData } = this.props;
		const orderBy = property;
		let order = "desc";

		let data = existingData.gsp;

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		data.sort(function (a, b) {
			var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
			if (numeric) {
				if (order === "desc")
					return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
				else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
			} else {
				if (order === "desc")
					return (b[orderBy] || "").toUpperCase() <
						(a[orderBy] || "").toUpperCase()
						? -1
						: 1;
				else
					return (a[orderBy] || "").toUpperCase() <
						(b[orderBy] || "").toUpperCase()
						? -1
						: 1;
			}
		});

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	// setAuthorized = () => {
	//   const { user } = this.props;
	//   const { authorized } = this.state;

	//   if (user && user.association && user.association.authorized_reporter) {
	//     if (!authorized && user && user.role === "GSP Representative") {
	//       this.setState({ authorized: true });
	//     }
	//   }
	// };

	handleAPIChange(data) {
		if (data.user_role === "GSP Representative") {
			this.setState({ authorized: true });
		}

		this.setState({ existingData: data.gsp, water_year: data.water_year });
	}

	render() {
		// this.setAuthorized();

		const { classes /*, authState*/ } = this.props;
		const {
			page,
			rowsPerPage,
			order,
			orderBy,
			authorized,
			existingData,
			// appConfig,
			// isFetching,
		} = this.state;

		let gsp_items = existingData ? existingData : [];

		if (existingData === undefined) {
			return (
				<AppContainer authenticated>
					<ReportingStatus
						apitarget={"reportingstatus"}
						handler={this.handleAPIChange.bind(this)}
					/>
					<NotReady message="Loading..." />
				</AppContainer>
			);
		} else {
			return (
				<AppContainer authenticated>
					<Grid container spacing={24}>
						<Grid item xs={12} className={classes.breadCrumb}>
							<BreadcrumbNav
								level1="Associated GSP's"
								level1link="/reporting"
							/>
						</Grid>
						<Grid item xs={12}>
							<span className={classes.lgHeader}>
								Report Water Supply and Storage Data for Associated GSP's
							</span>
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.root}>
								<Table className={classes.table}>
									<EnhancedTableHead
										columnData={columnData}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
									/>
									<TableBody>
										{gsp_items
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((item) => (
												<TableRow key={item.id}>
													<TableCell>{item.name}</TableCell>
													<TableCell>
														{item.authorized_reporter && authorized ? (
															<Tooltip title="Groundwater Storage Data">
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reporting/gwstorage/" + item.id,
																		})
																	}>
																	<Icon
																		path={mdiPencilOutline}
																		size={1}
																		color=""></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.gws_submitted !== "None" &&
														item.gws_ct > 0 ? (
															<Tooltip title={"View Data"}>
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reportinghistory/groundwaterstorage/" +
																				item.id,
																		})
																	}>
																	<Icon
																		path={mdiEye}
																		size={1}
																		color="primary"></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.gws_submitted}
													</TableCell>
													<TableCell>
														{item.authorized_reporter && authorized ? (
															<Tooltip title="Groundwater (Extraction) Data">
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reporting/gwextraction/" + item.id,
																		})
																	}>
																	<Icon
																		path={mdiPencilOutline}
																		size={1}
																		color="primary"></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.gwe_submitted !== "None" &&
														item.gwe_ct > 0 ? (
															<Tooltip title={"View Data"}>
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reportinghistory/groundwaterextraction/" +
																				item.id,
																		})
																	}>
																	<Icon
																		path={mdiEye}
																		size={1}
																		color="primary"></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.gwe_submitted}
													</TableCell>
													<TableCell>
														{item.authorized_reporter && authorized ? (
															<Tooltip title="Surface Water Supply Data">
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reporting/swsupply/" + item.id,
																		})
																	}>
																	<Icon
																		path={mdiPencilOutline}
																		size={1}
																		color="primary"></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.sws_submitted !== "None" &&
														item.sws_ct > 0 ? (
															<Tooltip title={"View Data"}>
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reportinghistory/surfacewatersupply/" +
																				item.id,
																		})
																	}>
																	<Icon
																		path={mdiEye}
																		size={1}
																		color="primary"></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.sws_submitted}
													</TableCell>
													<TableCell>
														{item.authorized_reporter && authorized ? (
															<Tooltip title="Total Water Use Data">
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reporting/totaluse/" + item.id,
																		})
																	}>
																	<Icon
																		path={mdiPencilOutline}
																		size={1}
																		color="primary"></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.twu_submitted !== "None" &&
														item.twu_ct > 0 ? (
															<Tooltip title={"View Data"}>
																<Button
																	className={classes.tableCellIcon}
																	onClick={() =>
																		this.props.history.push({
																			pathname:
																				"/reportinghistory/totalwateruse/" +
																				item.id,
																		})
																	}>
																	<Icon
																		path={mdiEye}
																		size={1}
																		color="primary"></Icon>{" "}
																</Button>
															</Tooltip>
														) : (
															<></>
														)}
														{item.twu_submitted}
													</TableCell>
												</TableRow>
											))}
										{gsp_items.length < 1 && (
											<TableRow>
												<TableCell colSpan={4} className={classes.centerAlign}>
													No GSPs Found
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									{gsp_items.length > 25 && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={4}
													count={gsp_items.length}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={this.handleChangePage}
													onChangeRowsPerPage={this.handleChangeRowsPerPage}
													ActionsComponent={TablePaginationActionsWrapped}
												/>
											</TableRow>
										</TableFooter>
									)}
								</Table>
							</Paper>
						</Grid>
					</Grid>
				</AppContainer>
			);
		}
	}
}
ReportingGSPs = connect(
	(state, ownProps) => ({
		authState: state.auth,
	}),
	{}
)(ReportingGSPs);

export default withStyles(styles)(ReportingGSPs);
