const app_title = "Madera Subbasin DMS";
const basin = "Madera Subbasin";
const contact_email = "test@test.org";

const map_initial_state = {
	lat: 37.0,
	lng: -120.0,
	zoom: 10,
	collapsed: false,
	showLayerList: true,
};

//these are layers on side of map
const map_layers = [
	{
		id: "esri_topographic",
		add: true,
		checked: true,
		type: "baselayer",
		type2: "topographic",
		name: "ESRI Topographic",
		attribution: "ESRI",
		url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
	},
	{
		id: "esri_streets",
		add: true,
		checked: false,
		type: "baselayer",
		type2: "street",
		name: "ESRI Streets",
		attribution: "ESRI",
		url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
	},
	{
		id: "esri_imagery",
		add: true,
		checked: false,
		type: "baselayer",
		type2: "imagery",
		name: "ESRI Imagery",
		attribution: "ESRI",
		url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
	},
	// {
	// 	id: "well_sites",
	// 	add: true,
	// 	checked: true,
	// 	title: "Well Sites",
	// 	type: "maplayer",
	// 	type2: "well_sites",
	// 	page_type: "Water Level",
	// 	link: "waterleveldetail/",
	// 	color: {
	// 		hex: "#0000FF",
	// 		rgb: "blue",
	// 	},
	// 	markerSize: 8,
	// },
	{
		id: "water_level_sites",
		add: true,
		checked: true,
		title: "Water Level - Representative Monitoring Sites",
		type: "maplayer",
		type2: "water_level_sites",
		page_type: "Water Level",
		link: "waterleveldetail/",
		color: {
			hex: "#CF382D",
			rgb: "red",
		},
		markerSize: 8,
		identify: true,
		index: 0,
	},
	{
		id: "waater_level_sites_other",
		add: true,
		checked: false,
		title: "Water Level - Other Monitoring Sites",
		type: "maplayer",
		type2: "waater_level_sites_other",
		page_type: "Water Level",
		link: "waterleveldetail/",
		color: {
			//uses color in maplayers.js from svg icon this doesnt matter
			hex: "#e59400",
			rgb: "orange",
		},
		square: true,
		markerSize: 8,
		identify: true,
		index: 1,
	},
	{
		id: "project_forms",
		add: true,
		checked: true,
		title: "Implementation Projects",
		type: "maplayer",
		type2: "project_forms",
		page_type: "Implementation Projects",
		link: "implementationprojects/",
		color: {
			hex: "#ff00ff",
			rgb: "magenta",
		},
		star: true,
		markerSize: 8,
		identify: true,
		index: 3,
	},
	{
		id: "water_quality_monitoring_sites",
		add: true,
		checked: false,
		title: "Water Quality Monitoring Sites",
		type: "maplayer",
		type2: "water_quality_monitoring_sites",
		page_type: "Water Quality",
		link: "waterqualitydetail/",
		color: {
			hex: "#2dd137",
			rgb: "green",
		},
		markerSize: 8,
		identify: true,
		index: 2,
	},
	{
		id: "subsidence_monitoring_sites",
		add: true,
		checked: false,
		title: "Subsidence Monitoring Sites",
		type: "maplayer",
		type2: "subsidence_monitoring_sites",
		page_type: "Subsidence",
		link: "subsidencedetail/",
		color: {
			hex: "#eaa334",
			rgb: "orange",
		},
		markerSize: 8,
		identify: true,
	},
	{
		id: "streamflow_monitoring_sites",
		add: true,
		checked: false,
		title: "Streamflow Monitoring Sites",
		type: "maplayer",
		type2: "streamflow_monitoring_sites",
		page_type: "Streamflow",
		link: "streamflowdetail/",
		color: {
			hex: "#1c69c6",
			rgb: "blue",
		},
		markerSize: 8,
		identify: true,
	},
	{
		id: "madera_subbasin_boundary",
		add: true,
		title: "Madera Subbasin Boundary",
		checked: true,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ title: "Boundary", color: "rgb(75,75,75)" }],
		layers: "madera_subbasin",
		map: "/var/www/madera/maps/map.map",
		url: "https://maderadms.houstoneng.net/cgi-bin/mapserv",
		link: "swgwdetail/",
	},
	{
		id: "gsp_boundaries",
		add: false,
		title: "GSP Boundaries",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ title: "GSP Boundaries", color: "0000FF" }],
		layers: "gsp",
		map: "/var/www/kings/maps/map.map",
		url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	},
	{
		id: "gsa_boundaries",
		add: true,
		title: "GSA Boundaries",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ title: "GSA Boundaries", color: "#0000FF" }],
		layers: "madera_gsa",
		map: "/var/www/madera/maps/map.map",
		url: "https://maderadms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
	{
		id: "clay",
		add: true,
		title: "E Clay Eastern Extent",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ color: "rgb(179,98,0)", title: "E Clay Eastern Extent" }],
		layers: "clay",
		map: "/var/www/madera/maps/map.map",
		url: "https://maderadms.houstoneng.net/cgi-bin/mapserv",
		identify: false,
	},
	{
		id: "landuse_2014",
		add: true,
		title: "Land Use DWR 2014",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [
			{ color: "rgba(166,206,227,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
			{ color: "rgba(115,76,0,0.5)", title: "Almonds" },
			{ color: "rgba(178,223,138,0.5)", title: "Apples" },
			{ color: "rgba(51,160,44,0.5)", title: "Bush Berries" },
			{ color: "rgba(251,154,153,0.5)", title: "Carrots" },
			{ color: "rgba(227,26,28,0.5)", title: "Cherries" },
			{ color: "rgba(252,170,0,0.5)", title: "Citrus" },
			{ color: "rgba(255,127,0,0.5)", title: "Corn, Sorghum and Sudan" },
			{ color: "rgba(202,178,214,0.5)", title: "Cotton" },
			{
				color: "rgba(106,61,154,0.5)",
				title: "Flowers, Nursery and Christmas Tree Farms",
			},
			{ color: "rgba(185,112,224,0.5)", title: "Grapes" },
			{ color: "rgba(177,89,40,0.5)", title: "Idle" },
			{ color: "rgba(141,211,199,0.5)", title: "Kiwis" },
			{ color: "rgba(65,171,93,0.5)", title: "Lettuce/Leafy Greens" },
			{ color: "rgba(190,186,218,0.5)", title: "Managed Wetland" },
			{ color: "rgba(251,128,114,0.5)", title: "Melons, Squash and Cucumbers" },
			{ color: "rgba(128,177,211,0.5)", title: "Miscellaneous Deciduous" },
			{ color: "rgba(253,180,98,0.5)", title: "Miscellaneous Grain and Hay" },
			{ color: "rgba(179,222,105,0.5)", title: "Miscellaneous Grasses" },
			{
				color: "rgba(252,205,229,0.5)",
				title: "Miscellaneous Subtropical Fruits",
			},
			{ color: "rgba(217,217,217,0.5)", title: "Miscellaneous Truck Crops" },
			{ color: "rgba(188,128,189,0.5)", title: "Mixed Pasture" },
			{ color: "rgba(204,235,197,0.5)", title: "Olives" },
			{ color: "rgba(255,237,111,0.5)", title: "Onions and Garlic" },
			{ color: "rgba(127,59,8,0.5)", title: "Peaches/Nectarines" },
			{ color: "rgba(179,88,6,0.5)", title: "Pears" },
			{ color: "rgba(194,82,60,0.5)", title: "Pistachios" },
			{ color: "rgba(253,184,99,0.5)", title: "Plums, Prunes and Apricots" },
			{ color: "rgba(254,224,182,0.5)", title: "Pomegranates" },
			{ color: "rgba(227,26,28,0.5)", title: "Potatoes and Sweet Potatoes" },
			{ color: "rgba(206,18,86,0.5)", title: "Strawberries" },
			{ color: "rgba(178,171,210,0.5)", title: "Tomatoes" },
			{ color: "rgba(128,115,172,0.5)", title: "Urban" },
			{ color: "rgba(84,39,136,0.5)", title: "Walnuts" },
			{ color: "rgba(45,0,75,0.5)", title: "Wheat" },
			{ color: "rgba(142,1,82,0.5)", title: "Young Perennials" },
		],
		layers: "landuse2014",
		map: "/var/www/madera/maps/map.map",
		url: "https://maderadms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
	{
		id: "landuse_2016",
		add: true,
		title: "Land Use DWR 2016",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [
			{ color: "rgba(252,170,0,0.5", title: "Citrus" },
			{ color: "rgba(185,112,224,0.5)", title: "Grapes" },
			{ color: "rgba(194,82,60,0.5)", title: "Pistachios" },
			{ color: "rgba(115,76,0,0.5)", title: "Almonds" },
			{ color: "rgba(168,122,29,0.5)", title: "Misc Deciduous" },
			{ color: "rgba(247,215,7,0.5)", title: "Cotton" },
			{ color: "rgba(168,168,0,0.5)", title: "Corn,Sorghum and Sudan" },
			{ color: "rgba(38 ,115,0,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
			{ color: "rgba(145,217,0,0.5)", title: "Misc Grain and Hay" },
			{ color: "rgba(163,255,115,0.5)", title: "Mixed Pasture" },
			{ color: "rgba(11,44,122,0.5)", title: "Misc Field Crops" },
			{
				color: "rgba(255,115,223,0.5)",
				title: "Truck,Nursery,and Berry Crops",
			},
			{ color: "rgba(255,167,127,0.5)", title: "Young Perennials" },
			{ color: "rgba(64,101,235,0.5)", title: "Managed Wetland" },
			{ color: "rgba(137,205,102,0.5)", title: "Native Vegetation" },
			{ color: "rgba(225,225,225,0.5)", title: "Semi Agricultural" },
			{ color: "rgba(178,178,178,0.5)", title: "Urban" },
			{ color: "rgba(255,235 ,175,0.5)", title: "Idle" },
		],
		layers: "landuse2016",
		map: "/var/www/madera/maps/map.map",
		url: "https://maderadms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
];

export { app_title, basin, contact_email, map_initial_state, map_layers };
