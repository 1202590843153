import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody"; 
import TextField from "../common/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import { BlueOnClick } from "../common/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import BreadcrumbNav from "../common/BreadCrumb";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SurfaceWaterSupply } from "./models";
import { createSelector } from "../common/orm";
import HelpLabel from "../common/HelpLabel";
import NumberFormat from "react-number-format";
/* Calc Method Start */
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Form } from "react-form";
// const getOptions = SurfaceWaterSupply.getOptions();
const CalcMethod = (props) => {
	// props: fieldName, currentValue
	const [value, setValue] = React.useState(props.currentValue); // eslint-disable-line no-unused-vars
	/* TODO: Figure out how to set the field's value so that the value of the field and make sure the form can read it */
	return (
		<FormControl component="fieldset">
			<RadioGroup
				style={{ flexWrap: "nowrap", marginLeft: "10px" }}
				aria-label="direct"
				name={props.fieldName}
				row>
				<FormControlLabel
					value="Direct"
					disabled={props.disabled}
					onChange={props.onChange}
					style={{ alignItems: "center" }}
					control={
						<Radio
							checked={props.value === "Direct"}
							style={{
								padding: "6px 0px 3px 7px",
								color: props.value === "Direct" ? "#13aff1" : "",
							}}
						/>
					}
					label="Direct"
					labelPlacement="end"
				/>
				<FormControlLabel
					value="Estimated"
					disabled={props.disabled}
					onChange={props.onChange}
					style={{ alignItems: "center" }}
					control={
						<Radio
							checked={props.value === "Estimated"}
							style={{
								padding: "6px 0 3px 7px",
								color: props.value === "Estimated" ? "#13aff1" : "",
							}}
						/>
					}
					label="Estimated"
					labelPlacement="end"
				/>
			</RadioGroup>
		</FormControl>
	);
};
/* Calc Method End */
const getUserData = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let user = session.User.filter((u) => u.id === options.id).toRefArray()[0];

		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		return user && association && gsp
			? {
					...user,
					association: association ? association : {},
					gsp: gsp ? gsp : {},
			  }
			: {};
	}
);

//eslint-disable-next-line
function formatDate(date) {
	var monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	var day = date.getUTCDate();
	var monthIndex = date.getUTCMonth();
	var year = date.getUTCFullYear();
	return monthNames[monthIndex] + " " + day + ", " + year;
}

const getWaterYear = createSelector((session, id) => {
	let wy = session.WaterYear.filter(
		(y) => y.is_active === true
	).toRefArray()[0];
	return {
		...wy,
	};
});

const currentSurfaceWaterSupply = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
		sws_id: parseInt(ownProps.match.params["id"]),
	}),
	(session, options) => {
		let gsp = session.GSP.filter((u) => u.id === options.gsp).toRefArray()[0];

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		if (options.sws_id) {
			let sws = session.SurfaceWaterSupply.filter(
				(y) => y.gsp === gsp.id && y.id === options.sws_id
			).toRefArray()[0];

			let wy =
				sws && sws.water_year_id
					? session.WaterYear.filter(
							(y) => y.id === sws.water_year_id
					  ).toRefArray()[0]
					: {};

			sws = { ...sws, wy: wy };

			return gsp && sws && wy ? sws : {};
		} else {
			return gsp && thisWaterYear
				? session.SurfaceWaterSupply.filter(
						(y) => y.water_year_id === thisWaterYear.id && y.gsp === gsp.id
				  ).toRefArray()[0]
				: {};
		}
	}
);
const historicSurfaceWaterSupply = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		return thisWaterYear && gsp
			? session.SurfaceWaterSupply.filter(
					(y) => y.water_year_id !== thisWaterYear.id && y.gsp === gsp.id
			  ).toRefArray()
			: {};
	}
);
const styles = (theme) => ({
	container: {
		paddingLeft: "10px",
	},
	hrclass: {
		borderColor: "primary",
	},
	tc: {
		padding: "4px 4px 4px 4px",
		maxWidth: "60px",
	},
	tcNumber: {
		padding: "4px 4px 4px 4px",
		maxWidth: "40px",
	},
	rightAligned: {
		textAlign: "right",
		padding: "4px 4px 4px 4px",
		maxWidth: "40px",
	},
	tcAccuracy: {
		padding: "4px 4px 4px 15px",
		maxWidth: "20px",
	},
	mdHeader: {
		fontSize: "18px",
		textDecoration: "underline",
		fontWeight: "normal",
	},
	lgHeader: {
		fontSize: "25px",
	},
});
class ReportingSWSupply extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			csrftoken: localStorage.auth_token,
			agency: "Not Available",
			submitted: false,
			saved: false,
			authorized: false,
			helpOpen: false,
			water_year: null,
			gsp_id: null,
			curdata: false,
			keyid: window.performance.now()
		};
	}
	componentDidMount() {
		const { authState, history } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
	}
	// setAuthorized = () => {
	// 	const { user } = this.props;
	// 	const { authorized } = this.state;
	// 	if (user && user.association && user.association.authorized_reporter) {
	// 		if (!authorized && user && user.role === "GSP Representative") {
	// 			this.setState({ authorized: true });
	// 		}
	// 	}
	// };
	pf = (val) => {
		if (val === "-" || val === "" || val === " ") {
			return parseFloat(0);
		} else if (typeof val === "string") {
			return parseFloat(val.replace(/,/g, ""));
		} else {
			return parseFloat(val);
		}
	};
	MAP_OPTIONS = (table) => {
		var t = table.map((row) => ({
			label: row.display_name,
			value: row.value,
		}));
		return t;
	};

	//set decimals and default styling
	NumberFormatCustom = (props) => {
		const { inputRef, onChange, ...other } = props;
		return (
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				onValueChange={(values) => {
					onChange({
						target: {
							value: values.value,
							name: other.name,
						},
					});
				}}
				thousandSeparator
				decimalScale={2}
			/>
		);
	};
	handleChange = (evt) => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});
	};
	handleChangeNumeric = (evt) => {
		const re = /^-?[-0-9\b]+$/;
		const val = evt.target.value.replace(/,/g, "");
		if (val === "" || re.test(val)) {
			this.setState({
				[evt.target.name]: val,
			});
		}
	};
	handleSave = (evt) => {
		evt.preventDefault();
		const { ormSurfaceWaterSupplyCreate, ormSurfaceWaterSupplyUpdate } =
			this.props;
		const { user, wateryr, history } = this.props;
		const { curdata } = this.state;
		this.setState({
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		});
		const stObj = {
			...this.state,
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		};
		if (!curdata) {
			ormSurfaceWaterSupplyCreate(stObj);
		} else {
			ormSurfaceWaterSupplyUpdate(stObj);
		}
		history.push("/reporting");
	};
	submitForm = (evt) => {
		evt.preventDefault();
		const { ormSurfaceWaterSupplyUpdate, history } = this.props;
		this.setState({
			submitted: true,
		});
		const stObj = {
			...this.state,
			submitted: true,
		};
		ormSurfaceWaterSupplyUpdate(stObj);
		history.push("/reporting");
	};

	defaultValues = (id) => {
		const { watercur } = this.props;

		//get all fields for to check for autopopulate on date
		let defaultValues = {};
	
		return watercur ? watercur : defaultValues;
	};
	render() {
		// this.setAuthorized();
		// this.populateStateFromHistory();
		const {
			submitted,
			saved,
			authorized,
			helpOpen,
		 
		} = this.state;
		const { classes, user,  watercur, waterhist, optionsReq } =
			this.props;
		let submitActive =
			(saved || this.state.curdata) && !submitted && authorized;
		//OPTIONS comes back as a promise
		// if (optionsReq && !siteChoices) {
		// 	optionsReq.then((data) => {
		// 		if (!this.state.siteChoices) {
		// 			this.setState({
		// 				siteChoices: data,
		// 			});
		// 		}
		// 	});
		// }
		return (
			<AppContainer authenticated>
				<div className={classes.container}>
					<Form
						key={watercur && watercur.id}
						dontValidateOnMount="false"
						getApi={(el) => (this.formApi = el)}
						// validateOnSubmit="true"
						defaultValues={this.defaultValues()}
						>
						{(formApi) => (
							<form onSubmit={formApi.submitForm}>
						{watercur && watercur.wy ? (
							<>
								<BreadcrumbNav
									level1="Surface Water Supply"
									level1link="/surfacewatersupply"
									level2="Surface Water Supply Details"
									level2link={
										"/reporting/swsupply/" +
										this.props.match.params["gsp"] +
										"/" +
										this.props.match.params["id"]
									}
								/>
								<br />
							</>
						) : (
							<>
								<BreadcrumbNav
									level1="Associated GSP's"
									level1link="/reporting"
									level2="Report Surface Water Supply Data"
									level2link={
										"/reporting/swsupply/" + this.props.match.params["gsp"]
									}
								/>
								<br />
							</>
						)}

						<Grid container spacing={24}>
							<Grid item xs={12}>
								{watercur && JSON.stringify(watercur) !== "{}" && (
									<>
									<span className={classes.lgHeader}>
										Water Year:{" "}
										{watercur.wy.water_year}{" "}
										/ Measurement Month:{" "}
										{watercur.month}
										
										</span>
									</>
								)} 
								</Grid>
								{watercur && watercur.gsp_label && (
									<Grid item xs={12}>
										<>
											<span>
												<b>GSP:</b> {watercur.gsp_label}  
											</span>
											<span style={{marginLeft:4}}>
												{"  |  "}<b> GSA: </b>{watercur.gsa_name_label}
											</span>
										</>
									</Grid>
								)}
							{watercur && watercur.wy ? (
								<></>
							) : (
								<>
									<Grid item xs={12}>
										<b>Role:</b> Your user account is{" "}
										{authorized ? "currently" : <b>NOT</b>} authorized to report
										data for:{" "}
										<b>
											{user && user.gsp
												? user.gsp.name
												: "(User not associated with GSP)"}
										</b>
										.
									</Grid>
									<Grid item xs={12}>
										<b>Status:</b> {submitted ? "Data" : "No data"} has been
										imported into the DMS for the current water year.{" "}
										{waterhist && waterhist.length && user && user.gsp ? (
											<a
												href={
													"/#/reportinghistory/surfacewatersupply/" +
													+this.props.match.params["gsp"]
												}>
												View Historic Data
											</a>
										) : (
											""
										)}
									</Grid>
								</>
							)}
							<hr />
							<Grid item xs={12}>
								<hr className={classes.hrclass} />
								<h2 className={classes.mdHeader}>
									Surface Water Supply Data
									<HelpLabel
										open={helpOpen}
										question="Surface Water Supply"
										showHelp={true}
										inputLabel={true}
										helpText={
											<>
												Water supplies used in the Madera Subbasin either
												diverted from a surface water body in or adjacent to the
												Subbasin and/or imported from outside the Subbasin and
												delivered to each specific water use sector.
											</>
										}
									/>
								</h2>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell xs={2} className={classes.tcNumber}>
												Water Source Type
											</TableCell>
								 
											<TableCell xs={1} className={classes.tcNumber}>
												Total
											</TableCell>
										 			
											<TableCell xs={1} className={classes.tcNumber}>
												Imported Central Valley Project
											</TableCell>
											<TableCell xs={1} className={classes.tcNumber}>
												Imported State Water Project
											</TableCell>
											<TableCell xs={1} className={classes.tcNumber}>
												Managed Imported Local Imported Supplies
											</TableCell>
											<TableCell xs={1} className={classes.tcNumber}>
												Local Supplies
											</TableCell>
											<TableCell xs={1} className={classes.tcNumber}>
												Recycled Water
											</TableCell>
											<TableCell xs={1} className={classes.tcNumber}>
												Flood Water of Any Water Source
											</TableCell>
											<TableCell xs={2} className={classes.tc}>
												Other
											</TableCell>
											<TableCell xs={3} className={classes.tc}>
												Other Description
											</TableCell>
											<TableCell xs={1} className={classes.tcAccuracy}>
												Explain methods used to calculate surface water supply"
											</TableCell>
										</TableRow>
										<TableRow>
												<TableCell xs={2} className={classes.tcNumber}>
													Total:
												</TableCell> 
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_total"}
														disabled={true}
														InputProps={{
															endAdornment: (//place AF at end of field
																<InputAdornment position="end">   
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom, //use decimal precision and inherited styling
														}}
														
														fullWidth
														useTwoDecimals
													
													/>
												</TableCell> 
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_import_cv"}
														disabled={true}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom,
														}}
														fullWidth
													
													/>
												</TableCell>
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_import_sw"}
														disabled={true}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom,
														}}
														fullWidth
													
													/>
												</TableCell> 
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_import_ls"}
														disabled={true}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom,
														}}
														fullWidth
													
													/>
												</TableCell>
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_localsup"}
														disabled={true}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom,
														}}
														fullWidth
													
													/>
												</TableCell> 
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_recycled"}
														disabled={true}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom,
														}}
														fullWidth
													
													/>
												</TableCell>
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_flood_water"}
														disabled={true}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom,
														}}
														fullWidth
													
													/>
												</TableCell> 
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_other"}
														disabled={true}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	AF
																</InputAdornment>
															),
															inputComponent: this.NumberFormatCustom,
														}}
														fullWidth
													
													/>
												</TableCell>
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_other_desc"}
														disabled={true}
														fullWidth
														InputProps 
														multiline={true}
														rows={1}
														rowsMax="4"
													/>
												</TableCell>
												<TableCell xs={1} className={classes.tcNumber}>
													<TextField
														field={"sw_calcm"}
														disabled={true}
														fullWidth
														InputProps //PIA but allows it to look liek the other fields
														multiline={true}
														rows={1}
														rowsMax="4"
													/>
												</TableCell>
										</TableRow>
											
									</TableBody>
								</Table>		 
							</Grid>
							{/* {watercur && watercur.wy ? (
								<></>
							) : (
								<>
									<Grid item xs={3}>
										<Button
											fullWidth
											variant="contained"
											type="button"
											label="Save"
											disabled={submitted || !authorized}
											onClick={this.handleSave}
											style={{
												backgroundColor:
													!submitted && authorized ? "#13aff1" : "#7ed2f5",
												color: "white",
											}}>
											Save
										</Button>
									</Grid>
									<Grid item xs={9}>
										<Button
											type="button"
											onClick={this.submitForm}
											disabled={!submitActive}
											style={{
												backgroundColor: submitActive ? "#13aff1" : "#7ed2f5",
												color: "white",
											}}>
											Submit Data as Final for Current Reporting Water Year
										</Button>
									</Grid>
								</>
							)} */}
						</Grid>
				 		</form>
						)}
					</Form>	
				</div>
			</AppContainer>
		);
	}
}
ReportingSWSupply = connect(
	(state, ownProps) => ({
		// optionsReq: getOptions(state, ownProps),
		wateryr: getWaterYear(state, ownProps),
		user: getUserData(state, ownProps),
		watercur: currentSurfaceWaterSupply(state, ownProps),
		waterhist: historicSurfaceWaterSupply(state, ownProps),
		authState: state.auth,
	}),
	{
		...SurfaceWaterSupply.actions,
		...authActions,
		...navActions,
	}
)(ReportingSWSupply);
export default withStyles(styles)(withRouter(ReportingSWSupply));
