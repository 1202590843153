import React from "react";
import { connect } from "react-redux";
import { createSelector } from "../components/common/orm";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Icon from "@mdi/react";
import { /*mdiEye, */ mdiLock, mdiLockOpen } from "@mdi/js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

const waterYear = createSelector((session, id) => {
	let wy = session.WaterYear.filter(
		(y) => y.is_active === true
	).toRefArray()[0];
	return {
		...wy,
	};
});

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://maderadms.houstoneng.net";

const styles = (theme) => ({
	tableCellIcon: {
		minWidth: 34,
		width: 34,
		marginRight: 0,
	},
	root: {
		display: "flex",
		"& > * + *": {
			marginLeft: 2,
		},
	},
	loading: {
		width: "20px !important",
		height: "20px !important",
	},
});

class BulkSubmitButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
			tooltipOpen: false,
		};
	}
	handleClose = () => {
		this.setState({ ...this.state, tooltipOpen: false });
	};

	handleOpen = () => {
		this.setState({ ...this.state, tooltipOpen: true });
	};

	render() {
		const { classes } = this.props;
		return (
			<Tooltip
				onClose={this.handleClose}
				onOpen={this.handleOpen}
				open={this.state.tooltipOpen}
				title={this.props.status === "Complete" ? "Unlock Data" : "Lock Data"}>
				<Button
					className={classes.tableCellIcon}
					onClick={() => this.fetchData()}
					disabled={this.state.isFetching}>
					{this.state.isFetching ? (
						<div className={classes.root}>
							<CircularProgress className={classes.loading} />
						</div>
					) : (
						<Icon
							path={this.props.status === "Complete" ? mdiLock : mdiLockOpen}
							size={this.props.iconSize}
							color={this.props.iconColor}></Icon>
					)}
				</Button>
			</Tooltip>
		);
	}

	fetchData = () => {
		//options for apitarget: [reportingstatus,importstatus,combinedstatus]

		let URL = isLocalhost ? LOCAL_URL : PROD_URL;

		const { authState, water_year, dataTarget /*, dataAction*/, gsp } =
			this.props;

		let token = authState && authState.user ? authState.user.auth_token : "";

		this.setState({ ...this.state, isFetching: true, tooltipOpen: false });

		let datasetname = "";

		switch (dataTarget) {
			case "gw":
				datasetname = "wellsitemeasurements";
				break;
			case "wq":
				datasetname = "waterqualitymeasurements";
				break;
			case "surf":
				datasetname = "surfacewatermeasurements";
				break;
			case "sub":
				datasetname = "subsidencemeasurements";
				break;
			case "gws":
				datasetname = "groundwaterstorage";
				break;
			case "gwe":
				datasetname = "groundwaterextraction";
				break;
			case "sws":
				datasetname = "surfacewatersupply";
				break;
			case "twu":
				datasetname = "totalwateruse";
				break;
			case "stream":
				datasetname = "streammonitoring";
				break;
			default:
		}

		let submitted = this.props.status === "Complete" ? 0 : 1;

		let payload = {
			datasetname: datasetname,
			submitted: submitted,
			gsp_id: gsp,
			water_year: water_year.water_year,
		};

		fetch(URL + "/bulksubmit", {
			method: "POST",
			body: JSON.stringify(payload),
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				this.setState({ ...this.state, isFetching: false });
				this.props.handler(result);
			})
			.catch((e) => {
				console.log(e);
				this.setState({ ...this.state, isFetching: false });
			});
	};
}
BulkSubmitButton = connect(
	(state, ownProps) => ({
		water_year: waterYear(state, ownProps),
		authState: state.auth,
	}),
	{}
)(BulkSubmitButton);

export default withStyles(styles)(BulkSubmitButton);
