import React from "react";
import { GSP, User, GSPAssociation } from "./models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import TextField from "../common/TextField";
import TablePaginationActionsWrapped from "../common/Paginator";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
import CheckboxGroup from "../common/CheckboxGroup";
import Select from "../common/Select";
import Icon from "@mdi/react";
import { mdiUndo, mdiCloseCircle, mdiCheckCircle } from "@mdi/js";
// import { createSelector } from "../common/orm";
import updateAuthorizedReporter from "../../api/updateAuthorizedReporter";

const getGSP = GSP.selectByUrlId((gsp) => ({
	...gsp.ref,
	_associations: gsp.gspassociations
		.all()
		.toModelArray()
		.map((a) => {
			return {
				agency: a.user.agency_label,
				name: a.user.first_name + " " + a.user.last_name,
				role: a.user.role,
				...a.ref,
			};
		}),
}));

const getUsers = User.selectAll("name");

const getAssociations = GSP.selectByUrlId();

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	tableCellIcon: {
		minWidth: 34,
		width: 34,
		marginRight: 0,
	},
	deleteWidth: {
		minWidth: 34,
		width: 34,
		marginRight: 16,
	},
	table: {
		width: "100%",
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
});

const columnData = [
	{ id: "name", numeric: false, label: "Name", allowSort: true },
	{ id: "agency", numeric: false, label: "Agency", allowSort: true },
	{
		id: "authorized_reporter",
		numeric: false,
		label: "Authorized Reporter",
		allowSort: true,
	},
	{ id: "actions", numeric: false, label: "", allowSort: false },
];

class AssociationTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order: "asc",
			orderBy: null,
			page: 0,
			rowsPerPage: 10,
			showDelete: false,
			deleteId: null,
			deleteName: null,
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		if (
			authState &&
			authState.user &&
			authState.user.role !== "Administrator"
		) {
			history.push("/dashboard");
		}
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}
		console.log(this.props.associations);

		order === "desc"
			? this.props.associations.sort((a, b) =>
					String(b[orderBy]).toUpperCase() < String(a[orderBy]).toUpperCase()
						? -1
						: 1
			  )
			: this.props.associations.sort((a, b) =>
					String(a[orderBy]).toUpperCase() < String(b[orderBy]).toUpperCase()
						? -1
						: 1
			  );

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	setAuthorizedReporter = (action, id) => {
		const { associations, ormGSPAssociationUpdateLocalOnly, authState } =
			this.props;

		let authorized_reporter = action ? 0 : 1;

		let token = authState && authState.user ? authState.user.auth_token : "";

		associations
			.filter((g) => g.id === id)
			.map((item) => {
				if (item.role !== "Agency") {
					return (
						ormGSPAssociationUpdateLocalOnly({
							...item,
							authorized_reporter: authorized_reporter,
						}),
						updateAuthorizedReporter(token, item.user, !action, item.gsp)
					);
				} else return null;
			});
	};

	render() {
		const { classes, associations, ormGSPAssociationDelete /*, authState*/ } =
			this.props;
		const {
			order,
			orderBy,
			page,
			rowsPerPage,
			showDelete,
			deleteName,
			deleteId,
		} = this.state;

		return (
			<Table className={classes.table}>
				<EnhancedTableHead
					columnData={columnData}
					order={order}
					orderBy={orderBy}
					onRequestSort={this.handleRequestSort}
				/>
				<TableBody>
					{associations
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((item) => (
							<TableRow key={item.id}>
								<TableCell>{item.name}</TableCell>
								<TableCell>{item.agency}</TableCell>
								<TableCell>
									<Tooltip
										title={
											item.authorized_reporter
												? "Revoke Reporting Authorization"
												: "Authorize User for Reporting GSP Data to the DMS (GSP Representative)"
										}>
										<Button
											className={classes.tableCellIcon}
											onClick={() =>
												this.setAuthorizedReporter(
													item.authorized_reporter,
													item.id
												)
											}>
											<Icon
												path={
													item.authorized_reporter
														? mdiCheckCircle
														: mdiCloseCircle
												}
												size={1}
												color={
													item.authorized_reporter ? "#28a745" : "#b20000"
												}></Icon>{" "}
										</Button>
									</Tooltip>
								</TableCell>
								<TableCell>
									<Tooltip title="Delete Association">
										<Button
											className={classes.deleteWidth}
											onClick={() =>
												this.setState({
													deleteName: item.name,
													deleteId: item.id,
													showDelete: true,
												})
											}>
											<DeleteIcon style={{ color: "#b20000" }} />
										</Button>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					{associations.length < 1 && (
						<TableRow>
							<TableCell colSpan={3} className={classes.centerAlign}>
								No Associations Defined
							</TableCell>
						</TableRow>
					)}
				</TableBody>
				{associations.length > 10 && (
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={3}
								count={associations.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActionsWrapped}
							/>
						</TableRow>
					</TableFooter>
				)}
				<WarningDialog
					confirmAction={() => {
						ormGSPAssociationDelete(deleteId);
						this.setState({ showDelete: false });
					}}
					cancelAction={() => this.setState({ showDelete: false })}
					open={showDelete}
					title="Delete Association"
					confirmText="Delete Association"
					text={
						"Are you sure you wish to delete the association of " +
						deleteName +
						"?"
					}
				/>
			</Table>
		);
	}
}

class EditGSP extends React.Component {
	constructor(props) {
		super(props);

		this.is_new = false;
		if (props.match.params["id"] === "new") this.is_new = true;
	}

	MAKE_OPTIONS = (table) => {
		return table.map((row) => ({
			label: row.first_name + " " + row.last_name,
			value: row.id,
		}));
	};

	errorValidator = (values) => {
		const validateName = (name) => {
			return !name ? "Field is required" : null;
		};
		var valObj = {};
		valObj["name"] = validateName(values.name);
		return valObj;
	};

	submitForm = (values) => {
		const { gsp, ormGSPCreate, ormGSPUpdate, match, history } = this.props;
		if (match.params["id"] === "new") {
			ormGSPCreate(values);
		} else {
			ormGSPUpdate({
				id: gsp.id,
				...values,
			});
		}
		history.push("/admingsps");
	};

	render() {
		const {
			gsp,
			// ass,
			classes,
			history,
			match,
			users,
			ormGSPAssociationDelete,
			ormGSPAssociationCreate,
			ormGSPAssociationUpdateLocalOnly,
			authState,
		} = this.props;
		if (gsp.name === "Not Found")
			// name = not found is from rest framework
			gsp.name = "";

		return (
			<AppContainer authenticated>
				<Grid container spacing={24}>
					<Grid item xs={12} className={classes.breadCrumb}>
						<BreadcrumbNav
							level1="Administration"
							level1link="/administration"
							level2="Manage GSPs"
							level2link="/admingsps"
							level3={(this.is_new ? "Add" : "Edit") + " GSP"}
							level3link={"/admingsps/" + match.params["id"]}
						/>
					</Grid>
					<Grid item xs={12} mg={9} lg={6} xl={3}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={() => history.push("/admingsps")}>
							<Icon path={mdiUndo} size={1} color="white"></Icon>
							&nbsp;&nbsp;&nbsp; Return to Manage GSPs
						</Button>
					</Grid>
					<Form
						dontValidateOnMount="true"
						validateOnSubmit="true"
						defaultValues={gsp}
						validateError={this.errorValidator}
						onSubmit={this.submitForm}>
						{(formApi) => (
							<Grid item xs={12}>
								<form onSubmit={formApi.submitForm}>
									<Grid container spacing={40}>
										<Grid item xs={10} sm={6} md={4} lg={3}>
											<TextField field="name" label="Name*" fullWidth />
										</Grid>
										<Grid item xs={10} sm={6} md={4} lg={3}>
											<TextField
												field="abbreviation"
												label="Abbreviation"
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid container spacing={40}>
										<Grid item xs={12} md={8} lg={6}>
											<CheckboxGroup
												field="data_submission"
												label="Annual Reporting Data Submission Requirements"
												row={true}
												options={[
													{ label: "Water Levels", value: "Water Levels" },
													{ label: "Water Quality", value: "Water Quality" },
													{
														label: "Interconnected SW Monitoring",
														value: "Interconnected SW Monitoring",
													},
													{ label: "Subsidence", value: "Subsidence" },
													{
														label: "GW Change in Storage",
														value: "GW Change in Storage",
													},
													{
														label: "GW Extraction",
														value: "GW Extraction",
													},
													{
														label: "GW Recharge",
														value: "GW Recharge",
													},
													{
														label: "Surface Water Supply",
														value: "Surface Water Supply",
													},
													{
														label: "Total Water Use",
														value: "Total Water Use",
													},
													{
														label: "Stream Monitoring",
														value: "Stream Monitoring",
													},
													{
														label: "Evapotranspiration",
														value: "Evapotranspiration",
													},
												]}
											/>
										</Grid>
									</Grid>
									{!this.is_new && (
										<>
											<Grid container spacing={40}>
												<Grid item xs={10} sm={6} md={4} lg={3}>
													<Select
														eventHandle={(val) => {
															ormGSPAssociationCreate({
																user: val,
																gsp: gsp.id,
															});
															formApi.setValue("_association", null);
														}}
														field="_association"
														label="Add User Association"
														options={this.MAKE_OPTIONS(users)}
														fullWidth
													/>
												</Grid>
											</Grid>
											<Grid container spacing={40}>
												<Grid item xs={12} md={8} lg={6}>
													<AssociationTable
														classes={classes}
														ormGSPAssociationDelete={ormGSPAssociationDelete}
														ormGSPAssociationUpdateLocalOnly={
															ormGSPAssociationUpdateLocalOnly
														}
														associations={gsp._associations}
														authState={authState}
													/>
												</Grid>
											</Grid>
										</>
									)}
									<Grid container spacing={40}>
										<Grid item xs={12} md={8} lg={6}>
											<Button
												variant="contained"
												type="submit"
												fullWidth
												color="primary">
												Save
											</Button>
										</Grid>
									</Grid>
								</form>
							</Grid>
						)}
					</Form>
				</Grid>
			</AppContainer>
		);
	}
}

EditGSP = connect(
	(state, ownProps) => ({
		gsp: getGSP(state, ownProps),
		ass: getAssociations(state, ownProps),
		users: getUsers(state, ownProps),
		authState: state.auth,
	}),
	{
		...GSP.actions,
		...GSPAssociation.actions,
	}
)(EditGSP);

export default withStyles(styles)(withRouter(EditGSP));
