import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import NumberFormat from "react-number-format";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

const historicWaterReporting = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
		admin:
			state.auth && state.auth.user && state.auth.user.role === "Administrator"
				? true
				: false,
	}),
	(session, options) => {
		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		if (options.admin) {
			gsp = session.GSP.filter((u) => u.id === options.gsp).toRefArray()[0];
		}

		let wy = session.WaterYear.all().toRefArray();

		if (gsp && gsp.id && wy) {
			gsp.gws = session.GroundwaterStorage.filter((r) => r.gsp === gsp.id)
				.toRefArray()
				.map((water) => {
					return {
						...water,
						...wy.find((w) => w.id === water.water_year_id),
					};
				});

			gsp.gwe = session.GroundwaterExtraction.filter(
				(r) => r.gsp_name_id === gsp.id
			)
				.toRefArray()
				.map((water) => {
					return {
						ge_id: water.id,
						...water,
						...wy.find(
							(w) =>
								w.water_year_start <= water.measurement_month &&
								w.water_year_end >= water.measurement_month
						),
					};
				});

			gsp.sws = session.SurfaceWaterSupply.filter((r) => r.gsp === gsp.id)
				.toRefArray()
				.map((water) => {
					return {
						...water,
						...wy.find((w) => w.id === water.water_year_id),
					};
				});

			gsp.twu = session.TotalWaterUse.filter((r) => r.gsp === gsp.id)
				.toRefArray()
				.map((water) => {
					return {
						...water,
						...wy.find((w) => w.id === water.water_year_id),
					};
				});
		}

		return gsp;
	}
);

const getUserData = createSelector(
	(state, ownProps) =>
		state.auth && state.auth.user ? state.auth.user.id : {},
	(session, id) => {
		let user = session.User.filter((u) => u.id === id).toRefArray()[0];
		let association = session.GSPAssociation.filter(
			(u) => u.user === id
		).toRefArray()[0];
		let gsp = session.GSP.filter(
			(u) => u.id === association.gsp
		).toRefArray()[0];

		return user && association && gsp
			? {
					...user,
					association: association ? association : {},
					gsp: gsp ? gsp : {},
			  }
			: {};
	}
);

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	expansionBorder: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
	},
	lastBorder: {
		[theme.breakpoints.up("xl")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	lastBorder2: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	expandedMargin: {
		marginTop: "12px",
		minHeight: "48px !important",
		maxHeight: 48,
	},
	borderContainer: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
		borderRight: "2px solid " + theme.palette.primary.light,
		borderLeft: "2px solid " + theme.palette.primary.light,
	},
	borderContainer2: {
		border: "1px solid rgb(221, 221, 221)",
	},
	buttonSelected: {
		border: "3px solid " + theme.palette.primary.main,
		minWidth: "44px",
	},
	buttonNotSelected: {
		border: "3px solid #666",
		minWidth: "44px",
	},
	iconSelected: {
		color: theme.palette.primary.main,
	},
	iconNotSelected: {
		color: "#666",
	},
	divideButton: {
		marginBottom: 16,
		width: "calc(50% - 16px)",
		marginTop: 16,
		minWidth: 44,
	},
	divideContainer: {
		backgroundColor: "#ddd",
		textAlign: "center",
		margin: "0 -4px",
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		minWidth: 500,
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	marginLeft: {
		marginLeft: -16,
	},
	chartTitle: {
		backgroundColor: "rgb(221, 221, 221)",
		textAlign: "center",
		padding: "8px 4px !important",
	},
});

class WaterReportingHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: "desc",
			orderBy: "water_year",
			page: 0,
			rowsPerPage: 10,
			histdata: [],
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}

	columnData(id) {
		const groundwaterStorageCols = [
			{ id: "water_year", numeric: true, label: "Year", allowSort: true },
			{
				id: "gw_stor_ua",
				numeric: true,
				label: "Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_stor_la",
				numeric: true,
				label: "Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_stor_total",
				numeric: true,
				label: "Total",
				allowSort: true,
			},
			{
				id: "gw_stor_method_notes",
				numeric: false,
				label: "Explain Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_stor_acc_notes",
				numeric: false,
				label: "Explain Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: false,
				label: "Submitted",
				allowSort: true,
			},
		];

		const groundwaterExtractionCols = [
			{
				id: "gsp_name_label",
				numeric: false,
				label: "GSP Organization Name",
				allowSort: true,
			},
			{
				id: "gsa_name_label",
				numeric: false,
				label: "GSA Organization Name",
				allowSort: true,
			},
			{
				id: "measurement_month",
				numeric: false,
				label: "Month",
				allowSort: true,
				width: 80,
			},

			{
				id: "gw_dom_ua",
				numeric: true,
				label: "Urban | Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_dom_la",
				numeric: true,
				label: "Urban | Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_dom_total",
				numeric: true,
				label: "Urban | Total",
				allowSort: true,
			},
			{
				id: "gw_dom_calcm",
				numeric: true,
				label: "Urban | Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_dom_acc",
				numeric: false,
				label: "Urban | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_dom_method",
				numeric: false,
				label: "Urban | Method",
				allowSort: true,
			},
			{
				id: "gw_dom_method_desc",
				numeric: false,
				label: "Urban | Method Description",
				allowSort: true,
				width: 800,
			},

			{
				id: "gw_ind_ua",
				numeric: true,
				label: "Industrial | Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_ind_la",
				numeric: true,
				label: "Industrial | Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_ind_total",
				numeric: true,
				label: "Industrial | Total",
				allowSort: true,
			},
			{
				id: "gw_ind_calcm",
				numeric: true,
				label: "Industrial | Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_ind_acc",
				numeric: false,
				label: "Industrial | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_ind_method",
				numeric: false,
				label: "Industrial | Method",
				allowSort: true,
			},
			{
				id: "gw_ind_method_desc",
				numeric: false,
				label: "Industrial | Method Description",
				allowSort: true,
				width: 800,
			},

			{
				id: "gw_agg_ua",
				numeric: true,
				label: "Agricultural | Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_agg_la",
				numeric: true,
				label: "Agricultural | Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_agg_total",
				numeric: true,
				label: "Agricultural | Total",
				allowSort: true,
			},
			{
				id: "gw_agg_calcm",
				numeric: true,
				label: "Agricultural | Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_agg_acc",
				numeric: false,
				label: "Agricultural | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_agg_method",
				numeric: false,
				label: "Agricultural | Method",
				allowSort: true,
			},
			{
				id: "gw_agg_method_desc",
				numeric: false,
				label: "Agricultural | Method Description",
				allowSort: true,
				width: 800,
			},

			{
				id: "gw_wet_ua",
				numeric: true,
				label: "Managed Wetlands | Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_wet_la",
				numeric: true,
				label: "Managed Wetlands | Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_wet_total",
				numeric: true,
				label: "Managed Wetlands | Total",
				allowSort: true,
			},
			{
				id: "gw_wet_calcm",
				numeric: true,
				label: "Managed Wetlands | Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_wet_acc",
				numeric: false,
				label: "Managed Wetlands | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_wet_method",
				numeric: false,
				label: "Managed Wetlands | Method",
				allowSort: true,
			},
			{
				id: "gw_wet_method_desc",
				numeric: false,
				label: "Managed Wetlands | Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_mr_ua",
				numeric: true,
				label: "Managed Recharge | Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_mr_la",
				numeric: true,
				label: "Managed Recharge | Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_mr_total",
				numeric: true,
				label: "Managed Recharge | Total",
				allowSort: true,
			},
			{
				id: "gw_mr_calcm",
				numeric: true,
				label: "Managed Recharge | Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_mr_acc",
				numeric: false,
				label: "Managed Recharge | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_mr_method",
				numeric: false,
				label: "Managed Recharge | Method",
				allowSort: true,
			},
			{
				id: "gw_mr_method_desc",
				numeric: false,
				label: "Managed Recharge | Method Description",
				allowSort: true,
				width: 800,
			},

			{
				id: "gw_veg_ua",
				numeric: true,
				label: "Native Vegetation | Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_veg_la",
				numeric: true,
				label: "Native Vegetation | Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_veg_total",
				numeric: true,
				label: "Native Vegetation | Total",
				allowSort: true,
			},
			{
				id: "gw_veg_calcm",
				numeric: true,
				label: "Native Vegetation | Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_veg_acc",
				numeric: false,
				label: "Native Vegetation | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_veg_method",
				numeric: false,
				label: "Native Vegetation | Method",
				allowSort: true,
			},
			{
				id: "gw_veg_method_desc",
				numeric: false,
				label: "Native Vegetation | Method Description",
				allowSort: true,
				width: 800,
			},

			{
				id: "gw_other_desc",
				numeric: false,
				label: "Other | Description",
				allowSort: true,
				width: 600,
			},
			{
				id: "gw_other_ua",
				numeric: true,
				label: "Other | Upper Aquifier",
				allowSort: true,
			},
			{
				id: "gw_other_la",
				numeric: true,
				label: "Other | Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_other_total",
				numeric: true,
				label: "Other | Total",
				allowSort: true,
			},
			{
				id: "gw_other_calcm",
				numeric: true,
				label: "Other | Calculation Method",
				allowSort: true,
			},
			{
				id: "gw_other_acc",
				numeric: false,
				label: "Other | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_other_method",
				numeric: false,
				label: "Other | Method",
				allowSort: true,
			},
			{
				id: "gw_other_method_desc",
				numeric: false,
				label: "Other | Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "submitted",
				numeric: false,
				label: "Submitted",
				allowSort: true,
			},
		];

		const surfacewaterSupplyCols = [
			{ id: "water_year", numeric: true, label: "Year", allowSort: true },
			{
				id: "sw_dom_import_cv",
				numeric: true,
				label: "Urban | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "sw_dom_import_sw",
				numeric: true,
				label: "Urban | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "sw_dom_import_l",
				numeric: true,
				label: "Urban | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "sw_dom_riv",
				numeric: true,
				label: "Urban | River",
				allowSort: true,
			},
			{
				id: "sw_dom_creek",
				numeric: true,
				label: "Urban | Creek",
				allowSort: true,
			},
			{
				id: "sw_dom_recycled",
				numeric: true,
				label: "Urban | Recycled",
				allowSort: true,
			},
			{
				id: "sw_dom_desalination",
				numeric: true,
				label: "Urban | Desalination",
				allowSort: true,
			},
			{
				id: "sw_dom_other",
				numeric: true,
				label: "Urban | Other",
				allowSort: true,
			},
			{
				id: "sw_dom_other_desc",
				numeric: true,
				label: "Urban | Other Desc.",
				allowSort: true,
			},
			{
				id: "sw_dom_total",
				numeric: true,
				label: "Urban | Total",
				allowSort: true,
			},
			{
				id: "sw_dom_notes",
				numeric: false,
				label: "Urban | Source Notes",
				allowSort: true,
			},
			{
				id: "sw_dom_calcm",
				numeric: false,
				label: "Urban | Calculation Method",
				allowSort: true,
			},
			{
				id: "sw_dom_acc",
				numeric: false,
				label: "Urban | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "sw_ind_import_cv",
				numeric: true,
				label: "Industrial | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "sw_ind_import_sw",
				numeric: true,
				label: "Industrial | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "sw_ind_import_l",
				numeric: true,
				label: "Industrial | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "sw_ind_riv",
				numeric: true,
				label: "Industrial | River",
				allowSort: true,
			},
			{
				id: "sw_ind_creek",
				numeric: true,
				label: "Industrial | Creek",
				allowSort: true,
			},
			{
				id: "sw_ind_recycled",
				numeric: true,
				label: "Industrial | Recycled",
				allowSort: true,
			},
			{
				id: "sw_ind_desalination",
				numeric: true,
				label: "Industrial | Desalination",
				allowSort: true,
			},
			{
				id: "sw_ind_other",
				numeric: true,
				label: "Industrial | Other",
				allowSort: true,
			},
			{
				id: "sw_ind_other_desc",
				numeric: true,
				label: "Industrial | Other Desc.",
				allowSort: true,
			},
			{
				id: "sw_ind_total",
				numeric: true,
				label: "Industrial | Total",
				allowSort: true,
			},
			{
				id: "sw_ind_notes",
				numeric: false,
				label: "Industrial | Source Notes",
				allowSort: true,
			},
			{
				id: "sw_ind_calcm",
				numeric: false,
				label: "Industrial | Calculation Method",
				allowSort: true,
			},
			{
				id: "sw_ind_acc",
				numeric: false,
				label: "Industrial | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "sw_agg_import_cv",
				numeric: true,
				label: "Agricultural | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "sw_agg_import_sw",
				numeric: true,
				label: "Agricultural | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "sw_agg_import_l",
				numeric: true,
				label: "Agricultural | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "sw_agg_riv",
				numeric: true,
				label: "Agricultural | River",
				allowSort: true,
			},
			{
				id: "sw_agg_creek",
				numeric: true,
				label: "Agricultural | Creek",
				allowSort: true,
			},
			{
				id: "sw_agg_recycled",
				numeric: true,
				label: "Agricultural | Recycled",
				allowSort: true,
			},
			{
				id: "sw_agg_desalination",
				numeric: true,
				label: "Agricultural | Desalination",
				allowSort: true,
			},
			{
				id: "sw_agg_other",
				numeric: true,
				label: "Agricultural | Other",
				allowSort: true,
			},
			{
				id: "sw_agg_other_desc",
				numeric: true,
				label: "Agricultural | Other Desc.",
				allowSort: true,
			},
			{
				id: "sw_agg_total",
				numeric: true,
				label: "Agricultural | Total",
				allowSort: true,
			},
			{
				id: "sw_agg_notes",
				numeric: false,
				label: "Agricultural | Source Notes",
				allowSort: true,
			},
			{
				id: "sw_agg_calcm",
				numeric: false,
				label: "Agricultural | Calculation Method",
				allowSort: true,
			},
			{
				id: "sw_agg_acc",
				numeric: false,
				label: "Agricultural | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "sw_wet_import_cv",
				numeric: true,
				label: "Managed Wetlands | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "sw_wet_import_sw",
				numeric: true,
				label: "Managed Wetlands | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "sw_wet_import_l",
				numeric: true,
				label: "Managed Wetlands | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "sw_wet_riv",
				numeric: true,
				label: "Managed Wetlands | River",
				allowSort: true,
			},
			{
				id: "sw_wet_creek",
				numeric: true,
				label: "Managed Wetlands | Creek",
				allowSort: true,
			},
			{
				id: "sw_wet_recycled",
				numeric: true,
				label: "Managed Wetlands | Recycled",
				allowSort: true,
			},
			{
				id: "sw_wet_desalination",
				numeric: true,
				label: "Managed Wetlands | Desalination",
				allowSort: true,
			},
			{
				id: "sw_wet_other",
				numeric: true,
				label: "Managed Wetlands | Other",
				allowSort: true,
			},
			{
				id: "sw_wet_other_desc",
				numeric: true,
				label: "Managed Wetlands | Other Desc.",
				allowSort: true,
			},
			{
				id: "sw_wet_total",
				numeric: true,
				label: "Managed Wetlands | Total",
				allowSort: true,
			},
			{
				id: "sw_wet_notes",
				numeric: false,
				label: "Managed Wetlands | Source Notes",
				allowSort: true,
			},
			{
				id: "sw_wet_calcm",
				numeric: false,
				label: "Managed Wetlands | Calculation Method",
				allowSort: true,
			},
			{
				id: "sw_wet_acc",
				numeric: false,
				label: "Managed Wetlands | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "sw_out_import_cv",
				numeric: true,
				label: "Outside Subbasin | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "sw_out_import_sw",
				numeric: true,
				label: "Outside Subbasin | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "sw_out_import_l",
				numeric: true,
				label: "Outside Subbasin | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "sw_out_riv",
				numeric: true,
				label: "Outside Subbasin | River",
				allowSort: true,
			},
			{
				id: "sw_out_creek",
				numeric: true,
				label: "Outside Subbasin | Creek",
				allowSort: true,
			},
			{
				id: "sw_out_recycled",
				numeric: true,
				label: "Outside Subbasin | Recycled",
				allowSort: true,
			},
			{
				id: "sw_out_desalination",
				numeric: true,
				label: "Outside Subbasin | Desalination",
				allowSort: true,
			},
			{
				id: "sw_out_other",
				numeric: true,
				label: "Outside Subbasin | Other",
				allowSort: true,
			},
			{
				id: "sw_out_other_desc",
				numeric: true,
				label: "Outside Subbasin | Other Desc.",
				allowSort: true,
			},
			{
				id: "sw_out_total",
				numeric: true,
				label: "Outside Subbasin | Total",
				allowSort: true,
			},
			{
				id: "sw_out_notes",
				numeric: false,
				label: "Outside Subbasin | Source Notes",
				allowSort: true,
			},
			{
				id: "sw_out_calcm",
				numeric: false,
				label: "Outside Subbasin | Calculation Method",
				allowSort: true,
			},
			{
				id: "sw_out_acc",
				numeric: false,
				label: "Outside Subbasin | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "sw_veg_import_cv",
				numeric: true,
				label: "Native Vegetation | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "sw_veg_import_sw",
				numeric: true,
				label: "Native Vegetation | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "sw_veg_import_l",
				numeric: true,
				label: "Native Vegetation | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "sw_veg_riv",
				numeric: true,
				label: "Native Vegetation | River",
				allowSort: true,
			},
			{
				id: "sw_veg_creek",
				numeric: true,
				label: "Native Vegetation | Creek",
				allowSort: true,
			},
			{
				id: "sw_veg_recycled",
				numeric: true,
				label: "Native Vegetation | Recycled",
				allowSort: true,
			},
			{
				id: "sw_veg_desalination",
				numeric: true,
				label: "Native Vegetation | Desalination",
				allowSort: true,
			},
			{
				id: "sw_veg_other",
				numeric: true,
				label: "Native Vegetation | Other",
				allowSort: true,
			},
			{
				id: "sw_veg_other_desc",
				numeric: true,
				label: "Native Vegetation | Other Desc.",
				allowSort: true,
			},
			{
				id: "sw_veg_total",
				numeric: true,
				label: "Native Vegetation | Total",
				allowSort: true,
			},
			{
				id: "sw_veg_notes",
				numeric: false,
				label: "Native Vegetation | Source Notes",
				allowSort: true,
			},
			{
				id: "sw_veg_calcm",
				numeric: false,
				label: "Native Vegetation | Calculation Method",
				allowSort: true,
			},
			{
				id: "sw_veg_acc",
				numeric: false,
				label: "Native Vegetation | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "sw_other_use_desc",
				numeric: true,
				label: "Other | Other Use Desc.",
				allowSort: true,
			},
			{
				id: "sw_other_import_cv",
				numeric: true,
				label: "Other | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "sw_other_import_sw",
				numeric: true,
				label: "Other | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "sw_other_import_l",
				numeric: true,
				label: "Other | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "sw_other_riv",
				numeric: true,
				label: "Other | River",
				allowSort: true,
			},
			{
				id: "sw_other_creek",
				numeric: true,
				label: "Other | Creek",
				allowSort: true,
			},
			{
				id: "sw_other_recycled",
				numeric: true,
				label: "Other | Recycled",
				allowSort: true,
			},
			{
				id: "sw_other_desalination",
				numeric: true,
				label: "Other | Desalination",
				allowSort: true,
			},
			{
				id: "sw_other_other",
				numeric: true,
				label: "Other | Other",
				allowSort: true,
			},
			{
				id: "sw_other_other_desc",
				numeric: true,
				label: "Other | Other Desc.",
				allowSort: true,
			},
			{
				id: "sw_other_total",
				numeric: true,
				label: "Other | Total",
				allowSort: true,
			},
			{
				id: "sw_other_notes",
				numeric: false,
				label: "Other | Source Notes",
				allowSort: true,
			},
			{
				id: "sw_other_calcm",
				numeric: false,
				label: "Other | Calculation Method",
				allowSort: true,
			},
			{
				id: "sw_other_acc",
				numeric: false,
				label: "Other | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "submitted",
				numeric: false,
				label: "Submitted",
				allowSort: true,
			},
		];

		const totalWaterUseCols = [
			{ id: "water_year", numeric: true, label: "Year", allowSort: true },
			{
				id: "t_dom_import_cv",
				numeric: true,
				label: "Urban | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_dom_import_sw",
				numeric: true,
				label: "Urban | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_dom_import_l",
				numeric: true,
				label: "Urban | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_dom_riv",
				numeric: true,
				label: "Urban | River",
				allowSort: true,
			},
			{
				id: "t_dom_creek",
				numeric: true,
				label: "Urban | Creek",
				allowSort: true,
			},
			{
				id: "t_dom_gw",
				numeric: true,
				label: "Urban | Ground Water",
				allowSort: true,
			},
			{
				id: "t_dom_recycled",
				numeric: true,
				label: "Urban | Recycled",
				allowSort: true,
			},
			{
				id: "t_dom_reused",
				numeric: true,
				label: "Urban | Reused",
				allowSort: true,
			},
			{
				id: "t_dom_other",
				numeric: true,
				label: "Urban | Other",
				allowSort: true,
			},
			{
				id: "t_dom_other_desc",
				numeric: true,
				label: "Urban | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_dom_total",
				numeric: true,
				label: "Urban | Total",
				allowSort: true,
			},
			{
				id: "t_dom_notes",
				numeric: false,
				label: "Urban | Source Notes",
				allowSort: true,
			},
			{
				id: "t_dom_calcm",
				numeric: false,
				label: "Urban | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_dom_acc",
				numeric: false,
				label: "Urban | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_ind_import_cv",
				numeric: true,
				label: "Industrial | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_ind_import_sw",
				numeric: true,
				label: "Industrial | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_ind_import_l",
				numeric: true,
				label: "Industrial | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_ind_riv",
				numeric: true,
				label: "Industrial | River",
				allowSort: true,
			},
			{
				id: "t_ind_creek",
				numeric: true,
				label: "Industrial | Creek",
				allowSort: true,
			},
			{
				id: "t_ind_gw",
				numeric: true,
				label: "Industrial | Ground Water",
				allowSort: true,
			},
			{
				id: "t_ind_recycled",
				numeric: true,
				label: "Industrial | Recycled",
				allowSort: true,
			},
			{
				id: "t_ind_reused",
				numeric: true,
				label: "Industrial | Reused",
				allowSort: true,
			},
			{
				id: "t_ind_other",
				numeric: true,
				label: "Industrial | Other",
				allowSort: true,
			},
			{
				id: "t_ind_other_desc",
				numeric: true,
				label: "Industrial | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_ind_total",
				numeric: true,
				label: "Industrial | Total",
				allowSort: true,
			},
			{
				id: "t_ind_notes",
				numeric: false,
				label: "Industrial | Source Notes",
				allowSort: true,
			},
			{
				id: "t_ind_calcm",
				numeric: false,
				label: "Industrial | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_ind_acc",
				numeric: false,
				label: "Industrial | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_agg_import_cv",
				numeric: true,
				label: "Agricultural | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_agg_import_sw",
				numeric: true,
				label: "Agricultural | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_agg_import_l",
				numeric: true,
				label: "Agricultural | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_agg_riv",
				numeric: true,
				label: "Agricultural | River",
				allowSort: true,
			},
			{
				id: "t_agg_creek",
				numeric: true,
				label: "Agricultural | Creek",
				allowSort: true,
			},
			{
				id: "t_agg_gw",
				numeric: true,
				label: "Agricultural | Ground Water",
				allowSort: true,
			},
			{
				id: "t_agg_recycled",
				numeric: true,
				label: "Agricultural | Recycled",
				allowSort: true,
			},
			{
				id: "t_agg_reused",
				numeric: true,
				label: "Agricultural | Reused",
				allowSort: true,
			},
			{
				id: "t_agg_other",
				numeric: true,
				label: "Agricultural | Other",
				allowSort: true,
			},
			{
				id: "t_agg_other_desc",
				numeric: true,
				label: "Agricultural | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_agg_total",
				numeric: true,
				label: "Agricultural | Total",
				allowSort: true,
			},
			{
				id: "t_agg_notes",
				numeric: false,
				label: "Agricultural | Source Notes",
				allowSort: true,
			},
			{
				id: "t_agg_calcm",
				numeric: false,
				label: "Agricultural | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_agg_acc",
				numeric: false,
				label: "Agricultural | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_wet_import_cv",
				numeric: true,
				label: "Managed Wetlands | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_wet_import_sw",
				numeric: true,
				label: "Managed Wetlands | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_wet_import_l",
				numeric: true,
				label: "Managed Wetlands | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_wet_riv",
				numeric: true,
				label: "Managed Wetlands | River",
				allowSort: true,
			},
			{
				id: "t_wet_creek",
				numeric: true,
				label: "Managed Wetlands | Creek",
				allowSort: true,
			},
			{
				id: "t_wet_gw",
				numeric: true,
				label: "Managed Wetlands | Ground Water",
				allowSort: true,
			},
			{
				id: "t_wet_recycled",
				numeric: true,
				label: "Managed Wetlands | Recycled",
				allowSort: true,
			},
			{
				id: "t_wet_reused",
				numeric: true,
				label: "Managed Wetlands | Reused",
				allowSort: true,
			},
			{
				id: "t_wet_other",
				numeric: true,
				label: "Managed Wetlands | Other",
				allowSort: true,
			},
			{
				id: "t_wet_other_desc",
				numeric: true,
				label: "Managed Wetlands | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_wet_total",
				numeric: true,
				label: "Managed Wetlands | Total",
				allowSort: true,
			},
			{
				id: "t_wet_notes",
				numeric: false,
				label: "Managed Wetlands | Source Notes",
				allowSort: true,
			},
			{
				id: "t_wet_calcm",
				numeric: false,
				label: "Managed Wetlands | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_wet_acc",
				numeric: false,
				label: "Managed Wetlands | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_dist_man_import_cv",
				numeric: true,
				label: "District Managed Recharge | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_dist_man_import_sw",
				numeric: true,
				label: "District Managed Recharge | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_dist_man_import_l",
				numeric: true,
				label: "District Managed Recharge | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_dist_man_riv",
				numeric: true,
				label: "District Managed Recharge | River",
				allowSort: true,
			},
			{
				id: "t_dist_man_creek",
				numeric: true,
				label: "District Managed Recharge | Creek",
				allowSort: true,
			},
			{
				id: "t_dist_man_gw",
				numeric: true,
				label: "District Managed Recharge | Ground Water",
				allowSort: true,
			},
			{
				id: "t_dist_man_recycled",
				numeric: true,
				label: "District Managed Recharge | Recycled",
				allowSort: true,
			},
			{
				id: "t_dist_man_reused",
				numeric: true,
				label: "District Managed Recharge | Reused",
				allowSort: true,
			},
			{
				id: "t_dist_man_other",
				numeric: true,
				label: "District Managed Recharge | Other",
				allowSort: true,
			},
			{
				id: "t_dist_man_other_desc",
				numeric: true,
				label: "District Managed Recharge | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_dist_man_total",
				numeric: true,
				label: "District Managed Recharge | Total",
				allowSort: true,
			},
			{
				id: "t_dist_man_notes",
				numeric: false,
				label: "District Managed Recharge | Source Notes",
				allowSort: true,
			},
			{
				id: "t_dist_man_calcm",
				numeric: false,
				label: "District Managed Recharge | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_dist_man_acc",
				numeric: false,
				label: "District Managed Recharge | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_in_lieu_import_cv",
				numeric: true,
				label: "In-Lieu Use | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_in_lieu_import_sw",
				numeric: true,
				label: "In-Lieu Use | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_in_lieu_import_l",
				numeric: true,
				label: "In-Lieu Use | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_in_lieu_riv",
				numeric: true,
				label: "In-Lieu Use | River",
				allowSort: true,
			},
			{
				id: "t_in_lieu_creek",
				numeric: true,
				label: "In-Lieu Use | Creek",
				allowSort: true,
			},
			{
				id: "t_in_lieu_gw",
				numeric: true,
				label: "In-Lieu Use | Ground Water",
				allowSort: true,
			},
			{
				id: "t_in_lieu_recycled",
				numeric: true,
				label: "In-Lieu Use | Recycled",
				allowSort: true,
			},
			{
				id: "t_in_lieu_reused",
				numeric: true,
				label: "In-Lieu Use | Reused",
				allowSort: true,
			},
			{
				id: "t_in_lieu_other",
				numeric: true,
				label: "In-Lieu Use | Other",
				allowSort: true,
			},
			{
				id: "t_in_lieu_other_desc",
				numeric: true,
				label: "In-Lieu Use | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_in_lieu_total",
				numeric: true,
				label: "In-Lieu Use | Total",
				allowSort: true,
			},
			{
				id: "t_in_lieu_notes",
				numeric: false,
				label: "In-Lieu Use | Source Notes",
				allowSort: true,
			},
			{
				id: "t_in_lieu_calcm",
				numeric: false,
				label: "In-Lieu Use | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_in_lieu_acc",
				numeric: false,
				label: "In-Lieu Use | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_veg_import_cv",
				numeric: true,
				label: "Native Vegetation | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_veg_import_sw",
				numeric: true,
				label: "Native Vegetation | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_veg_import_l",
				numeric: true,
				label: "Native Vegetation | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_veg_riv",
				numeric: true,
				label: "Native Vegetation | River",
				allowSort: true,
			},
			{
				id: "t_veg_creek",
				numeric: true,
				label: "Native Vegetation | Creek",
				allowSort: true,
			},
			{
				id: "t_veg_gw",
				numeric: true,
				label: "Native Vegetation | Ground Water",
				allowSort: true,
			},
			{
				id: "t_veg_recycled",
				numeric: true,
				label: "Native Vegetation | Recycled",
				allowSort: true,
			},
			{
				id: "t_veg_reused",
				numeric: true,
				label: "Native Vegetation | Reused",
				allowSort: true,
			},
			{
				id: "t_veg_other",
				numeric: true,
				label: "Native Vegetation | Other",
				allowSort: true,
			},
			{
				id: "t_veg_other_desc",
				numeric: true,
				label: "Native Vegetation | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_veg_total",
				numeric: true,
				label: "Native Vegetation | Total",
				allowSort: true,
			},
			{
				id: "t_veg_notes",
				numeric: false,
				label: "Native Vegetation | Source Notes",
				allowSort: true,
			},
			{
				id: "t_veg_calcm",
				numeric: false,
				label: "Native Vegetation | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_veg_acc",
				numeric: false,
				label: "Native Vegetation | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_out_import_cv",
				numeric: true,
				label: "Outside Subbasin | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_out_import_sw",
				numeric: true,
				label: "Outside Subbasin | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_out_import_l",
				numeric: true,
				label: "Outside Subbasin | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_out_riv",
				numeric: true,
				label: "Outside Subbasin | River",
				allowSort: true,
			},
			{
				id: "t_out_creek",
				numeric: true,
				label: "Outside Subbasin | Creek",
				allowSort: true,
			},
			{
				id: "t_out_gw",
				numeric: true,
				label: "Outside Subbasin | Ground Water",
				allowSort: true,
			},
			{
				id: "t_out_recycled",
				numeric: true,
				label: "Outside Subbasin | Recycled",
				allowSort: true,
			},
			{
				id: "t_out_reused",
				numeric: true,
				label: "Outside Subbasin | Reused",
				allowSort: true,
			},
			{
				id: "t_out_other",
				numeric: true,
				label: "Outside Subbasin | Other",
				allowSort: true,
			},
			{
				id: "t_out_other_desc",
				numeric: true,
				label: "Outside Subbasin | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_out_total",
				numeric: true,
				label: "Outside Subbasin | Total",
				allowSort: true,
			},
			{
				id: "t_out_notes",
				numeric: false,
				label: "Outside Subbasin | Source Notes",
				allowSort: true,
			},
			{
				id: "t_out_calcm",
				numeric: false,
				label: "Outside Subbasin | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_out_acc",
				numeric: false,
				label: "Outside Subbasin | Calculation Accuracy",
				allowSort: true,
			},

			{
				id: "t_other_use_desc",
				numeric: true,
				label: "Other | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_other_import_cv",
				numeric: true,
				label: "Other | Imported Central Valley Project",
				allowSort: true,
			},
			{
				id: "t_other_import_sw",
				numeric: true,
				label: "Other | Imported State Water Project",
				allowSort: true,
			},
			{
				id: "t_other_import_l",
				numeric: true,
				label: "Other | Imported Local Imported Supplies",
				allowSort: true,
			},
			{
				id: "t_other_riv",
				numeric: true,
				label: "Other | River",
				allowSort: true,
			},
			{
				id: "t_other_creek",
				numeric: true,
				label: "Other | Creek",
				allowSort: true,
			},
			{
				id: "t_other_gw",
				numeric: true,
				label: "Other | Ground Water",
				allowSort: true,
			},
			{
				id: "t_other_recycled",
				numeric: true,
				label: "Other | Recycled",
				allowSort: true,
			},
			{
				id: "t_other_reused",
				numeric: true,
				label: "Other | Reused",
				allowSort: true,
			},
			{
				id: "t_other_other",
				numeric: true,
				label: "Other | Other",
				allowSort: true,
			},
			{
				id: "t_other_other_desc",
				numeric: true,
				label: "Other | Other Desc.",
				allowSort: true,
			},
			{
				id: "t_other_total",
				numeric: true,
				label: "Other | Total",
				allowSort: true,
			},
			{
				id: "t_other_notes",
				numeric: false,
				label: "Other | Source Notes",
				allowSort: true,
			},
			{
				id: "t_other_calcm",
				numeric: false,
				label: "Other | Calculation Method",
				allowSort: true,
			},
			{
				id: "t_other_acc",
				numeric: false,
				label: "Other | Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: false,
				label: "Submitted",
				allowSort: true,
			},
		];

		if (id === "groundwaterstorage") {
			return groundwaterStorageCols;
		} else if (id === "groundwaterextraction") {
			return groundwaterExtractionCols;
		} else if (id === "surfacewatersupply") {
			return surfacewaterSupplyCols;
		} else if (id === "totalwateruse") {
			return totalWaterUseCols;
		} else {
			return [];
		}
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		const colType = this.props.match.params["id"];

		const columnData = this.columnData(colType);

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		this.state.histdata.sort(function (a, b) {
			var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
			if (numeric) {
				if (order === "desc")
					return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
				else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
			} else {
				if (order === "desc")
					return (b[orderBy] + "" || "").toUpperCase() <
						(a[orderBy] + "" || "").toUpperCase()
						? -1
						: 1;
				else
					return (a[orderBy] + "" || "").toUpperCase() <
						(b[orderBy] + "" || "").toUpperCase()
						? -1
						: 1;
			}
		});

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	render() {
		const { classes /*, user,*/, waterhist } = this.props;
		const { page, rowsPerPage, order, orderBy, histdata } = this.state;

		let histName = "";
		const histId = this.props.match.params["id"];

		if (histId === "groundwaterstorage") {
			histName = "Groundwater Change in Storage History";

			if (waterhist && waterhist.gws) {
				if (!histdata.length) {
					this.setState({ histdata: waterhist.gws });
				}
			}
		} else if (histId === "groundwaterextraction") {
			histName = "GW Extraction History";
			if (waterhist && waterhist.gwe) {
				if (!histdata.length) {
					this.setState({ histdata: waterhist.gwe });
				}
			}
		} else if (histId === "surfacewatersupply") {
			histName = "Surface Water Supply History";
			if (waterhist && waterhist.sws) {
				if (!histdata.length) {
					this.setState({ histdata: waterhist.sws });
				}
			}
		} else if (histId === "totalwateruse") {
			histName = "Total Water Use History";
			if (waterhist && waterhist.twu) {
				if (!histdata.length) {
					this.setState({ histdata: waterhist.twu });
				}
			}
		}

		let level1 = "Associated GSP's";
		let level1link = "/reporting";
		let level2 = histName;
		let level2link =
			"/reportinghistory/" + histId + "/" + this.props.match.params["gsp"];

		if (this.props.location.pathname.indexOf("reportinghistorygsp") > -1) {
			level1 = "GSP Reporting Status";
			level1link = "/gspreportingstatus";
			level2link =
				"/reportinghistorygsp/" + histId + "/" + this.props.match.params["gsp"];
		}

		return (
			<AppContainer authenticated>
				<Grid container spacing={24}>
					<Grid item xs={12} className={classes.breadCrumb}>
						<BreadcrumbNav
							level1={level1}
							level1link={level1link}
							level2={level2}
							level2link={level2link}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">
							{histName + (waterhist ? ": " + waterhist.name : "")}
						</Typography>
					</Grid>

					{histId === "groundwaterstorage" && histdata ? (
						<Grid item xs={12}>
							<Paper className={classes.root}>
								<Table className={classes.table}>
									<EnhancedTableHead
										columnData={this.columnData(histId)}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
									/>
									<TableBody>
										{histdata
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((item) => (
												<TableRow key={item.id}>
													<TableCell>{item.water_year}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_stor_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_stor_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_stor_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_stor_method_notes}</TableCell>
													<TableCell>{item.gw_stor_acc_notes}</TableCell>
													<TableCell>
														{item.submitted ? "True" : "False"}
													</TableCell>
												</TableRow>
											))}
										{histdata.length < 1 && (
											<TableRow>
												<TableCell colSpan={7} className={classes.centerAlign}>
													No Historical Data Found
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									{histdata.length > rowsPerPage && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={7}
													count={histdata.length}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={this.handleChangePage}
													onChangeRowsPerPage={this.handleChangeRowsPerPage}
													ActionsComponent={TablePaginationActionsWrapped}
												/>
											</TableRow>
										</TableFooter>
									)}
								</Table>
							</Paper>
						</Grid>
					) : (
						<></>
					)}
					{histId === "groundwaterextraction" && histdata ? (
						<Grid item xs={12}>
							<Paper className={classes.root}>
								<Table className={classes.table}>
									<EnhancedTableHead
										columnData={this.columnData(histId)}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
									/>
									<TableBody>
										{histdata
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((item) => (
												<TableRow key={item.ge_id}>
													<TableCell>{item.gsp_name_label}</TableCell>
													<TableCell>{item.gsa_name_label}</TableCell>
													<TableCell>{item.measurement_month}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_dom_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_dom_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_dom_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_dom_calcm}</TableCell>
													<TableCell>{item.gw_dom_acc}</TableCell>
													<TableCell>{item.gw_dom_method}</TableCell>
													<TableCell>{item.gw_dom_method_notes}</TableCell>

													<TableCell>
														<NumberFormat
															value={item.gw_ind_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_ind_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_ind_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_ind_calcm}</TableCell>
													<TableCell>{item.gw_ind_acc}</TableCell>
													<TableCell>{item.gw_ind_method}</TableCell>
													<TableCell>{item.gw_ind_method_notes}</TableCell>

													<TableCell>
														<NumberFormat
															value={item.gw_agg_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_agg_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_agg_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_agg_calcm}</TableCell>
													<TableCell>{item.gw_agg_acc}</TableCell>
													<TableCell>{item.gw_agg_method}</TableCell>
													<TableCell>{item.gw_agg_method_notes}</TableCell>

													<TableCell>
														<NumberFormat
															value={item.gw_wet_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_wet_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_wet_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_wet_calcm}</TableCell>
													<TableCell>{item.gw_wet_acc}</TableCell>
													<TableCell>{item.gw_wet_method}</TableCell>
													<TableCell>{item.gw_wet_method_notes}</TableCell>

													<TableCell>
														<NumberFormat
															value={item.gw_mr_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_mr_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_mr_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_mr_calcm}</TableCell>
													<TableCell>{item.gw_mr_acc}</TableCell>
													<TableCell>{item.gw_mr_method}</TableCell>
													<TableCell>{item.gw_mr_method_notes}</TableCell>

													<TableCell>
														<NumberFormat
															value={item.gw_veg_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_veg_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_veg_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_veg_calcm}</TableCell>
													<TableCell>{item.gw_veg_acc}</TableCell>
													<TableCell>{item.gw_veg_method}</TableCell>
													<TableCell>{item.gw_veg_method_notes}</TableCell>

													<TableCell>{item.gw_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_other_ua}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_other_la}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.gw_other_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.gw_other_calcm}</TableCell>
													<TableCell>{item.gw_other_acc}</TableCell>
													<TableCell>{item.gw_other_method}</TableCell>
													<TableCell>{item.gw_other_method_notes}</TableCell>
													<TableCell>
														{item.submitted ? "True" : "False"}
													</TableCell>
												</TableRow>
											))}
										{histdata.length < 1 && (
											<TableRow>
												<TableCell colSpan={7} className={classes.centerAlign}>
													No Historical Data Found
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									{histdata.length > rowsPerPage && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={7}
													count={histdata.length}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={this.handleChangePage}
													onChangeRowsPerPage={this.handleChangeRowsPerPage}
													ActionsComponent={TablePaginationActionsWrapped}
												/>
											</TableRow>
										</TableFooter>
									)}
								</Table>
							</Paper>
						</Grid>
					) : (
						<></>
					)}
					{histId === "surfacewatersupply" && histdata ? (
						<Grid item xs={12}>
							<Paper className={classes.root}>
								<Table className={classes.table}>
									<EnhancedTableHead
										columnData={this.columnData(histId)}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
									/>
									<TableBody>
										{histdata
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((item) => (
												<TableRow key={item.id}>
													<TableCell>{item.water_year}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_import_cv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_import_sw}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_import_l}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_riv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_creek}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_recycled}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_desalination}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_other}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_dom_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_dom_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_dom_notes}</TableCell>
													<TableCell>{item.sw_dom_calcm}</TableCell>
													<TableCell>{item.sw_dom_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_import_cv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_import_sw}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_import_l}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_riv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_creek}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_recycled}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_desalination}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_other}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_ind_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_ind_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_ind_notes}</TableCell>
													<TableCell>{item.sw_ind_calcm}</TableCell>
													<TableCell>{item.sw_ind_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_import_cv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_import_sw}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_import_l}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_riv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_creek}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_recycled}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_desalination}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_other}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_agg_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_agg_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_agg_notes}</TableCell>
													<TableCell>{item.sw_agg_calcm}</TableCell>
													<TableCell>{item.sw_agg_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_import_cv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_import_sw}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_import_l}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_riv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_creek}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_recycled}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_desalination}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_other}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_wet_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_wet_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_wet_notes}</TableCell>
													<TableCell>{item.sw_wet_calcm}</TableCell>
													<TableCell>{item.sw_wet_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_import_cv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_import_sw}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_import_l}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_riv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_creek}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_recycled}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_desalination}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_other}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_out_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_out_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_out_notes}</TableCell>
													<TableCell>{item.sw_out_calcm}</TableCell>
													<TableCell>{item.sw_out_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_import_cv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_import_sw}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_import_l}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_riv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_creek}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_recycled}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_desalination}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_other}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_veg_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_veg_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_veg_notes}</TableCell>
													<TableCell>{item.sw_veg_calcm}</TableCell>
													<TableCell>{item.sw_veg_acc}</TableCell>
													<TableCell>{item.sw_other_use_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_import_cv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_import_sw}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_import_l}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_riv}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_creek}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_recycled}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_desalination}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_other}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_other_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sw_other_total}
															displayType={"text"}
															thousandSeparator={true}
														/>
													</TableCell>
													<TableCell>{item.sw_other_notes}</TableCell>
													<TableCell>{item.sw_other_calcm}</TableCell>
													<TableCell>{item.sw_other_acc}</TableCell>
													<TableCell>
														{item.submitted ? "True" : "False"}
													</TableCell>
												</TableRow>
											))}
										{histdata.length < 1 && (
											<TableRow>
												<TableCell colSpan={7} className={classes.centerAlign}>
													No Historical Data Found
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									{histdata.length > rowsPerPage && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={7}
													count={histdata.length}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={this.handleChangePage}
													onChangeRowsPerPage={this.handleChangeRowsPerPage}
													ActionsComponent={TablePaginationActionsWrapped}
												/>
											</TableRow>
										</TableFooter>
									)}
								</Table>
							</Paper>
						</Grid>
					) : (
						<></>
					)}
					{histId === "totalwateruse" && histdata ? (
						<Grid item xs={12}>
							<Paper className={classes.root}>
								<Table className={classes.table}>
									<EnhancedTableHead
										columnData={this.columnData(histId)}
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
									/>
									<TableBody>
										{histdata
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((item) => (
												<TableRow key={item.id}>
													<TableCell>{item.water_year}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_dom_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dom_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_dom_notes}</TableCell>
													<TableCell>{item.t_dom_calcm}</TableCell>
													<TableCell>{item.t_dom_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_ind_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_ind_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_ind_notes}</TableCell>
													<TableCell>{item.t_ind_calcm}</TableCell>
													<TableCell>{item.t_ind_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_agg_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_agg_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_agg_notes}</TableCell>
													<TableCell>{item.t_agg_calcm}</TableCell>
													<TableCell>{item.t_agg_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_wet_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_wet_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_wet_notes}</TableCell>
													<TableCell>{item.t_wet_calcm}</TableCell>
													<TableCell>{item.t_wet_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_dist_man_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_dist_man_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_dist_man_notes}</TableCell>
													<TableCell>{item.t_dist_man_calcm}</TableCell>
													<TableCell>{item.t_dist_man_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_in_lieu_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_in_lieu_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_in_lieu_notes}</TableCell>
													<TableCell>{item.t_in_lieu_calcm}</TableCell>
													<TableCell>{item.t_in_lieu_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_veg_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_veg_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_veg_notes}</TableCell>
													<TableCell>{item.t_veg_calcm}</TableCell>
													<TableCell>{item.t_veg_acc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_out_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_out_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_out_notes}</TableCell>
													<TableCell>{item.t_out_calcm}</TableCell>
													<TableCell>{item.t_out_acc}</TableCell>
													<TableCell>{item.t_other_use_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_import_cv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_import_sw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_import_l}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_riv}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_creek}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_gw}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_recycled}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_reused}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_other}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_other_other_desc}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.t_other_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={0}
														/>
													</TableCell>
													<TableCell>{item.t_other_notes}</TableCell>
													<TableCell>{item.t_other_calcm}</TableCell>
													<TableCell>{item.t_other_acc}</TableCell>
													<TableCell>
														{item.submitted ? "True" : "False"}
													</TableCell>
												</TableRow>
											))}
										{histdata.length < 1 && (
											<TableRow>
												<TableCell colSpan={7} className={classes.centerAlign}>
													No Historical Data Found
												</TableCell>
											</TableRow>
										)}
									</TableBody>
									{histdata.length > rowsPerPage && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={7}
													count={histdata.length}
													rowsPerPage={rowsPerPage}
													page={page}
													onChangePage={this.handleChangePage}
													onChangeRowsPerPage={this.handleChangeRowsPerPage}
													ActionsComponent={TablePaginationActionsWrapped}
												/>
											</TableRow>
										</TableFooter>
									)}
								</Table>
							</Paper>
						</Grid>
					) : (
						<></>
					)}
				</Grid>
			</AppContainer>
		);
	}
}
WaterReportingHistory = connect(
	(state, ownProps) => ({
		user: getUserData(state, ownProps),
		waterhist: historicWaterReporting(state, ownProps),
		authState: state.auth,
	}),
	{}
)(WaterReportingHistory);

export default withStyles(styles)(WaterReportingHistory);
