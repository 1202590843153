import React from "react";
import CollapsibleLayersControl from "./CollapsibleLayersControl";
import { Map, Marker, TileLayer, WMSTileLayer } from "react-leaflet";

import * as app_settings from "../../../app_settings";

class EditMap extends React.Component {
	constructor() {
		super();
		this.state = app_settings.map_initial_state;
	}

	render() {
		const { draggable } = this.props;
		const { collapsed } = this.state;
		const position = [
			this.props.geometry
				? !isNaN(this.props.geometry.coordinates[1]) //this is here for manual editing of lat/long making sure its a number
					? this.props.geometry.coordinates[1]
					: this.state.lat
				: this.state.lat,
			this.props.geometry
				? !isNaN(this.props.geometry.coordinates[0])
					? this.props.geometry.coordinates[0]
					: this.state.lng
				: this.state.lng,
		];

		const zoom =
			this.props.geometry && !this.props.zoom
				? 12
				: this.props.zoom
				? this.props.zoom
				: this.state.zoom;
		var controlPosition = this.props.controlPosition;

		if (!controlPosition) {
			controlPosition = "topleft";
		}

		return (
			<Map
				className="editmap-container"
				height={this.props.height}
				width={this.props.width}
				center={position}
				zoom={zoom}>
				<Marker
					draggable={draggable ? false : true}
					onDragend={this.props.handler}
					position={position}></Marker>
				<CollapsibleLayersControl
					position="topright"
					controlPosition={controlPosition}
					collapsed={collapsed}>
					{app_settings.map_layers
						.filter((l) => l.add === true && l.type === "baselayer")
						.map((l) => (
							<CollapsibleLayersControl.BaseLayer
								key={l.id}
								checked={l.checked}
								name={l.name}>
								<TileLayer
									info={null}
									attribution={l.attribution}
									url={l.url}
								/>
							</CollapsibleLayersControl.BaseLayer>
						))}
					{app_settings.map_layers
						.filter((l) => l.add === true && l.type === "wmstilelayer")
						.map((l) => (
							<CollapsibleLayersControl.Overlay2
								key={l.id}
								checked={l.checked}
								name={l.title}>
								<WMSTileLayer
									info={l}
									layers={l.layers}
									transparent={l.transparent}
									opacity={l.opacity}
									format={l.format}
									map={l.map}
									legend={l.legend}
									url={l.url}
								/>
							</CollapsibleLayersControl.Overlay2>
						))}
				</CollapsibleLayersControl>
			</Map>
		);
	}
}

export default EditMap;
